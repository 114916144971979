import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Home from "../../components/Home";
import { HomePageContainer } from "./styles";
import ReactGA from 'react-ga';
import {useEffect} from 'react';
import WhatsappComponent from "../../components/WhatsappComponent";

const HomePage = () =>{
    useEffect(()=>{
        ReactGA.pageview(window.location.pathname);
    },[])
    
    return (
        <HomePageContainer>
            <Header />
            <Home />
            <Footer />
            <WhatsappComponent />
        </HomePageContainer>
    )
};

export default HomePage;