import { useEffect } from 'react';
import Router from "./routes";
import ReactGA from 'react-ga';
ReactGA.initialize('G-QZ95643DF0');

const App = () => {
  function Analytics(){
    ReactGA.pageview('Pagina inicial')
  }

  useEffect(() => {
    Analytics()
    //eslint-disable-next-line
  },[]);

  return (
    <Router />
  );
}

export default App;
