import { ClientesContainer, Links } from "./style";
import ambev from "../../assets/cliente-ambev.svg";
import redBull from "../../assets/cliente-red-full.svg";
import swift from "../../assets/cliente-swift.svg";
import pg from "../../assets/cliente-p&g.svg";
import mars from "../../assets/cliente-mars.svg";
import heineken from "../../assets/cliente-heineken.svg";
import seta from "../../assets/seta.png";
import { Link } from "react-router-dom";

const NossosClientes = () => {
  return (
    <ClientesContainer id="clientes">
      <div className="content">
        <h1>Nossos Clientes</h1>

        <div className="divImg">
          <div className="divScroll">
            <div>
              <div className="item">
                <img src={ambev} alt="ambev" id="ambev" />
              </div>
              <div className="item">
                <img src={redBull} alt="redBull" id="redBull" />
              </div>
              <div className="item">
                <img src={swift} alt="swift" id="swift" />
              </div>
              <div className="item">
                <img src={heineken} alt="heineken" id="heineken" />
              </div>
              <div className="item">
                <img src={pg} alt="pg" id="pg" />
              </div>
              <div className="item">
                <img src={mars} alt="mars" id="mars" />
              </div>
            </div>
          </div>
          <Links>
            <a className="link" href="#ambev">
              <button></button>
            </a>
            <a className="link" href="#redBull">
              <button></button>
            </a>
            <a className="link" href="#swift">
              <button></button>
            </a>
            <a className="link" href="#heineken">
              <button></button>
            </a>
            <a className="link" href="#pg">
              <button></button>
            </a>
            <a className="link" href="#mars">
              <button></button>
            </a>
          </Links>
          <Link className="nossosCases" to={"/cases"}>
            Conheça nossos cases <img src={seta} alt="seta" />
          </Link>
        </div>
      </div>
    </ClientesContainer>
  );
};

export default NossosClientes;
