import React, { useState } from "react";
import axios from "axios";
import { FormEmailContainer } from "./style";
import { toast } from "react-toastify";
import InputMask from "react-input-mask";

const FormEmail = () => {
  const [name, setName] = useState("");
  const [empresa, setEmpresa] = useState("");
  const [email, setEmail] = useState("");
  const [fone, setFone] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  async function sendEmail(e) {
    e.preventDefault();

    setIsLoading(true);

    try {
      const response = await axios.post(
        "https://imanageapi.azurewebsites.net/email_ifollow",
        {
          name,
          empresa,
          email,
          fone,
          message,
        }
      );

      if (response.status === 200) {
        console.log("E-mail enviado com sucesso!");
        setName("");
        setEmpresa("");
        setEmail("");
        setFone("");
        setMessage("");
        toast.success("E-mail enviado com sucesso!");
      } else {
        console.error("Erro ao enviar o e-mail");
        toast.error("Erro ao enviar o e-mail.");
      }
    } catch (error) {
      console.error("Erro ao enviar o e-mail:", error);
      toast.error("Erro ao enviar o e-mail.");
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <FormEmailContainer>
      <form className="divInput" onSubmit={sendEmail}>
        <h3>Entre em contato conosco e agende a nossa próxima reunião</h3>
        <p>Preencha seus dados e aguarde o contato de um especialista</p>

        <label>
          <input
            type="text"
            placeholder="Nome: "
            onChange={(e) => setName(e.target.value)}
            value={name}
            required
          />
        </label>
        <label>
          <input
            type="text"
            placeholder="Empresa: "
            onChange={(e) => setEmpresa(e.target.value)}
            value={empresa}
            required
          />
        </label>
        <label>
          <input
            type="email"
            placeholder="Email: "
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            required
          />
        </label>
        <label>
          <InputMask
            mask="(99) 99999-9999"
            className="masked-input"
            type="tel"
            placeholder="Telefone: "
            onChange={(e) => setFone(e.target.value)}
            value={fone}
            required
          />
        </label>
        <label>
          <textarea
            type="text"
            placeholder="Como podemos ajudar?"
            onChange={(e) => setMessage(e.target.value)}
            value={message}
            required
          />
        </label>
        <button type="submit" disabled={isLoading}>
          {isLoading ? "Enviando..." : "Enviar >"}
        </button>
      </form>
    </FormEmailContainer>
  );
};

export default FormEmail;
