import {
  ApresentacaoContainer,
  ComoFuncionaContainer,
  ContainerScroll,
  DigitalExperienceContainer,
  EntreEmContatoContainer,
  ImagesContainer,
  Links,
  PrincipaisVantagensContainer,
  Solucoes,
} from "./styles";
import pvd0 from "../../assets/pdv01.webp";
import pdv from "../../assets/pdv02.webp";
import pdv1 from "../../assets/pdv03.webp";
import pdv2 from "../../assets/pdv04.webp";
import pdv3 from "../../assets/pdv05.png";
import modernidade from "../../assets/modernidade-singularidade.svg";
import elmaChips from "../../assets/vantagem01.webp";
import branding from "../../assets/vantagem02.webp";
import iconsBranding from "../../assets/branding-power.svg";
import guaranaTela from "../../assets/vantagem03.webp";
import guaranaForm from "../../assets/guaranaForm.png";
import iconEconomia from "../../assets/economia-display.svg";
import elmaChipsTablet from "../../assets/vantagem04.webp";
import iconEscabilidade from "../../assets/escabilidade-agilade.svg";
import sunset from "../../assets/vantagem05.webp";
import iconMulti from "../../assets/multifuncionalidade.svg";
import maisControle from "../../assets/dxp.webp";
import carrossel01 from "../../assets/carrossel01.png";
import carrossel02 from "../../assets/carrossel02.png";
import carrossel03 from "../../assets/carrossel03.png";
import carrossel04 from "../../assets/carrossel04.png";
import carrossel05 from "../../assets/carrossel05.png";
import carrossel06 from "../../assets/carrossel06.png";
import carrossel07 from "../../assets/carrossel07.png";
import FlowIcone01 from "../../assets/icone-porque-digital-suporte.svg";
import FlowIcone02 from "../../assets/icone-porque-digital-acompanhamento.svg";
import FlowIcone03 from "../../assets/icone-porque-digital-relatorios.svg";
import FlowIcone04 from "../../assets/icone-porque-digital-monitoramento.svg";
import balao from "../../assets/balao-de-fala.png";
import { motion } from "framer-motion";
import imgDxp from "../../assets/digital-solucao.webp";
import logoDxp from "../../assets/digital-experience (1).svg";
import { Link } from "react-router-dom";
import FormEmail from "../FormEmail";
import seta from "../../assets/seta.png";

const DigitalExperience = () => {
  const handleWhatsAppClick = () => {
    const phoneNumber = "5511950853443";
    window.location.href = `https://api.whatsapp.com/send?phone=${phoneNumber}`;
  };
  return (
    <DigitalExperienceContainer>
      <ApresentacaoContainer>
        <div className="content">
          <div className="left">
            <h1>Já diziam por aí: quem não é visto, não é lembrado!</h1>
            <p>
              Mas, muito mais importante do que ser visto, é conseguir mostrar
              aquilo que você quer, na hora que você quiser.
            </p>
            <p className="paragraph">
              E é justamente isso que nós oferecemos com o
            </p>
            <img src={logoDxp} alt="iFollow" />
            <button onClick={handleWhatsAppClick}>
              Fale com um especialista {">"}
            </button>
          </div>

          <div className="right">
            <motion.div
              initial={{ x: "100%" }}
              animate={{ x: 0 }}
              exit={{ x: "100%" }}
              transition={{ duration: 1 }}
            >
              <img src={imgDxp} alt="Dxp" />
            </motion.div>
          </div>
        </div>
      </ApresentacaoContainer>

      <ComoFuncionaContainer id="como-funciona">
        <div className="white-container">
          <h2>Quer fazer o seu produto aparecer mais no PDV? </h2>

          <div className="content">
            <p>
            Com as nossas telas, as chamadas digital signage, é possível transmitir diversos conteúdos criativos à escolha do cliente para melhorar o monitoramento e a troca de mídia em todos os PDVs {" "}
              <span>
              instantaneamente e à distância, de qualquer lugar do mundo
              </span>
              , através do nosso aplicativo.
            </p>
            <br></br>

            <p>
              O Digital Experience possibilita uma{" "}
              <span>comunicação dinâmica e atrativa com shopper</span>, trazendo
              mais modernidade à sua marca. É a solução ideal para desenvolver
              uma campanha incrível para seu produto, proporcionar uma{" "}
              <span>ótima experiência de compra</span> e{" "}
              <span>fidelizar seus clientes</span>.
            </p>
          </div>
        </div>
        <div className="pdvs">
          <div>
            <img src={pvd0} alt="pdv" id="pdv0" />
          </div>
          <div>
            <img src={pdv} alt="pdv" id="pdv" />
          </div>
          <div>
            <img src={pdv1} alt="pdv" id="pdv1" />
          </div>
          <div>
            <img src={pdv2} alt="pdv" id="pdv2" />
          </div>
          <div>
            <img src={pdv3} alt="pdv" id="pdv3" />
          </div>
        </div>
        <Links>
          <a className="link" href="#pdv0">
            <button></button>
          </a>
          <a className="link" href="#pdv">
            <button></button>
          </a>
          <a className="link" href="#pdv1">
            <button></button>
          </a>
          <a className="link" href="#pdv2">
            <button></button>
          </a>
          <a className="link" href="#pdv3">
            <button></button>
          </a>
        </Links>
        <div className="purple-container">
          <div className="description-container">
            <div>
              <h2>E quais as vantagens ?</h2>
              <p>Mais destaque para seu produto</p>
            </div>
          </div>
          <div className="content">
            <p>
              Inserimos o Digital Experience nos displays desenvolvidos aos
              nossos clientes para que{" "}
              <span>saiam na frente de seus concorrentes</span> e tenham o{" "}
              <span>potencial visual</span> de suas marcas ainda mais
              explorados.
            </p>
          </div>
        </div>
      </ComoFuncionaContainer>

      <ImagesContainer>
        <div className="body">
          <div className="content">
            <img src={modernidade} alt="modernidade" />
            <h2>Modernidade e Singularidade</h2>

            <p>
              Agregar o Digital Experience ao momento de compra no PDV possibilita uma comunicação dinâmica e atrativa entre o anunciante e o comprador, atribuindo modernidade à memoria de marca do shopper e criando uma experiência de compra marcante, o que também influência na fidelização dos clientes.
            </p>
          </div>
          <div className="right">
            <img src={elmaChips} alt="elmaChips" />
          </div>
        </div>
        <div className="body-left">
          <div className="left">
            <img src={branding} alt="branding" />
          </div>
          <div className="content">
            <img src={iconsBranding} alt="Branding" />
            <h2>Branding Power e Sell Out </h2>

            <p>
              Diferenciação da concorrência, aumento da força da marca no PDV e
              impulsionamento das vendas na gôndola. Curiosidade: o sell out de
              uma peça com Digital Signage é em média 30% maior que uma peça
              comum.
            </p>
          </div>
        </div>
        <div className="body">
          <div className="content">
            <img src={iconEconomia} alt="Economia" />
            <h2>Economia com Displays Multimarcas</h2>

            <p>
              É possível fazer upload de qualquer conteúdo a qualquer momento,
              reduzindo a necessidade de produção de novos displays para a
              implementação de uma nova campanha ou para uma ativação de outra
              marca.
            </p>
          </div>
          <div className="right">
            <img src={guaranaTela} alt="guarana" />
          </div>
        </div>

        <div className="body-left">
          <div className="left">
            <img src={elmaChipsTablet} alt="elmaChips" />
          </div>
          <div className="content">
            <img src={iconEscabilidade} alt="Escabilidade" />
            <h2>Escabilidade e Agilidade</h2>

            <p>
              O Digital Experience é um Projeto totalmente escalável e ágil.
              Qualquer nova campanha ou ação pode ser realizada em questão de
              segundos em qualquer lugar.
            </p>
          </div>
        </div>
        <div className="body">
          <div className="content">
            <img src={iconMulti} alt="iconMulti" />
            <h2>Multifuncionalidade</h2>

            <p>
              Diferentes formatos de telas podem ser usados, tais como stoppers,
              faixas,pontas de gôndolas, ilhas e displays, dinamizando ainda
              mais a presença na gôndola e se adequando a diferentes perfis de
              campanhas.
            </p>
          </div>
          <div className="right">
            <img src={sunset} alt="sunset" />
          </div>
        </div>
      </ImagesContainer>

      <PrincipaisVantagensContainer>
        <div className="container">
          <div className="left">
            <h1>Mais Controle e gestão</h1>
            <p>
              Ainda oferecemos gestão pelo nosso sistema
              próprio de Digital Experience, onde é possível visualizar todas as
              suas telas e materiais.
            </p>
            <br></br>

            <p>
              Através da nossa dashboard você ainda tem uma visualização rápida
              de onde estão os seus materiais e quantos estão online e offline.
            </p>
          </div>
          <div className="right">
            <img src={maisControle} alt="Controle" />
          </div>
        </div>
        <div className="body">
          <h2>
            Por que escolher o Digital Experience da iFollow para sua empresa?
          </h2>
          <span>Inovação, tecnologia e gestão</span>

          <div className="card">
            <div>
              <img src={FlowIcone01} alt="Flow" />
              <p>Oferecemos suporte 24/7</p>
            </div>
            <div>
              <img src={FlowIcone02} alt="Flow" />
              <p>Portal de acompanhamento</p>
            </div>
            <div>
              <img src={FlowIcone03} alt="Flow" />
              <p>Relatórios personalizados</p>
            </div>
            <div>
              <img src={FlowIcone04} alt="Flow"></img>
              <p>Monitoramento de equipamentos</p>
            </div>
          </div>
        </div>
      </PrincipaisVantagensContainer>

      <ContainerScroll>
        <div className="container">
          <div className="left">
            <h2>
              Já imaginou os conteúdos de sua marca sendo transmitidos através
              das nossas telas ? Vamos modernizar ainda mais a sua presença nos
              PDVs e sua comunicação com o shopper?
            </h2>
            <Link to={""}>
              <button className="especialista" onClick={handleWhatsAppClick}>
                Fale com um especialista <img src={balao} alt="balao" />
              </button>
            </Link>
          </div>
          <div className="box">
            <div className="image-right">
              <div>
                <img src={carrossel01} alt="carrossel" id="carrossel01" />
                <img src={carrossel02} alt="carrossel" id="carrossel02" />
                <img src={carrossel03} alt="carrossel" id="carrossel03" />
                <img src={carrossel04} alt="carrossel" id="carrossel04" />
                <img src={carrossel05} alt="carrossel" id="carrossel05" />
                <img src={carrossel06} alt="carrossel" id="carrossel06" />
                <img src={carrossel07} alt="carrossel" id="carrossel07" />
              </div>
            </div>
          </div>
          <Links>
            <a className="link" href="#carrossel01">
              <button></button>
            </a>
            <a className="link" href="#carrossel02">
              <button></button>
            </a>
            <a className="link" href="#carrossel03">
              <button></button>
            </a>
            <a className="link" href="#carrossel04">
              <button></button>
            </a>
            <a className="link" href="#carrossel05">
              <button></button>
            </a>
            <a className="link" href="#carrossel06">
              <button></button>
            </a>
            <a className="link" href="#carrossel07">
              <button></button>
            </a>
          </Links>
        </div>
      </ContainerScroll>

      <Solucoes>
        <div id className="content">
          <div id className="content-left">
            <h2>Todas as soluções que você precisa. </h2>
          </div>
          <div className="content-right">
            <p>
            Nós te damos todo suporte necessário, desde a instalação das telas até sua <span>manutenção por toda a vida.</span> Fazemos também o monitoramento das telas e podemos ficar <span>responsáveis pelas trocas de conteúdo.</span> Além disso,  nossa empresa parceira pode te ajudar no <span>desenvolvimento desses criativos!</span>
            </p>
            <Link to="/cases">
              Conheça nossos cases <img src={seta} alt="seta" />
            </Link>
          </div>
        </div>
      </Solucoes>

      <EntreEmContatoContainer id="clientes">
        <div className="content">
          <img src={guaranaForm} alt="guarana" />
          <FormEmail />
        </div>
      </EntreEmContatoContainer>
    </DigitalExperienceContainer>
  );
};

export default DigitalExperience;
