import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Imanage from "../../components/Imanage";

import { ImanagePageContainer } from "./styles";
import ReactGA from 'react-ga';
import {useEffect} from 'react';
import WhatsappComponent from "../../components/WhatsappComponent";

const ImanagePage = () => {
    useEffect(()=>{
        ReactGA.pageview(window.location.pathname);
    },[])

    return (
        <ImanagePageContainer>
            <Header />
            <Imanage />
            <Footer />
            <WhatsappComponent />
        </ImanagePageContainer>
    )
};

export default ImanagePage;
