import styled from "styled-components";


export const FormEmailContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
  > .divInput {
    display: flex;
    flex-direction: column;
    width: 80%;
    align-items: center;
    @media screen and (max-width: 900px) {
        width: 100%;
      }

    h3 {
      font-size: 0.7rem;
      margin-bottom: 10px;
    }

    p {
    
      font-size: 0.7rem;
    }
    label{
      width: 100%;
    }
    textarea{
      width: 100%;
      max-height: 100px;
      border-radius: 8px;
      border: none;
      box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
      font-size: 0.8rem;
      margin-top: 20px;
      padding-left: 12px;
      padding-top: 5px;

      ::placeholder {
        padding: 10px;
        font-size: 0.7rem;
      }
    }

    input
   {
      width: 100%;
      padding: 13px;
      max-height: 200px;
      border-radius: 8px;
      border: none;
      box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
      font-size: 0.8rem;
      margin-top: 20px;

      ::placeholder {
        font-size: 0.7rem;
      }
    }
    textarea,
    input:focus {
      outline: none;
      resize: none;
    }
    .masked-input {
      color: gray;
    }

    button {
      margin: 20px 0 0 0;
      border: none;
      background-color: rgb(0, 174, 239);
      padding: 10px;
      width:50%;
      border-radius: 10px;
      font-size: 0.8rem;
      color: white;
      @media screen and (max-width: 900px) {
        width: 100%;
      }
    }
  }

  .input-error {
    color: red;
    font-size: 0.6rem;
    margin-top: 5px;
  }
`;
