import styled from 'styled-components';

export const WhatsappContainer = styled.div`
    display: flex;
    position: fixed;
    top: 85%;
    left: 92%;
    
    > button {
        background: none;
        border: none;
    }
    
    @media screen and (max-width: 700px){
        left: 80%;
        top: 82%;
    }

    @media screen and (max-height: 600px) {
        top: 65%;
    }
`;