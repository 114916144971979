import ReactWhatsapp from "react-whatsapp";
import { WhatsappContainer } from "./style";
import WhatsappIcon from '../../assets/whatsapp.svg';
import ReactGA from 'react-ga';
import { ReactSVG } from 'react-svg';

const WhatsappComponent = () => {
    const handleClick = () => {
        ReactGA.event({
          category: 'botão',
          action: 'clique',
          label: 'whatsapp'
        });
    }

    return (
        <WhatsappContainer onClick={handleClick}>
            <ReactWhatsapp number='+551195085-3443' aria-label="Whatsapp">
                <ReactSVG src={WhatsappIcon} alt='whatsapp'/>
            </ReactWhatsapp>
        </WhatsappContainer>
    );
};

export default WhatsappComponent;