import {
  ClientesContainer,
  QuemSomosContainer,
  SaibaMaisContainer,
  NossosNumerosContainer,
  AnalyticsContainer,
  InformaçõesContainer,
} from "./style";

import analytics from "../../assets/analytics_logo.svg";
import trafegoPessoas from "../../assets/informações-trafego-pessoas.svg";
import relogio from "../../assets/informações-horarios.svg";
import faixaEtaria from "../../assets/informações-faixa-etaria.svg";
import tempo from "../../assets/informações-tempo.svg";
import conversao from "../../assets/informações-conversão-atenção.svg";
import tempoEspera from "../../assets/informações-tempo-espera.svg";
import genero from "../../assets/informações-genero.svg";
import engajamento from "../../assets/informações-engajamento.svg";
import alvo from "../../assets/vantagens-campanhas.svg";
import resultado from "../../assets/vantagens-mensuração.svg";
import shopper from "../../assets/vantagens-melhorar-experiencia.svg";
import agilizar from "../../assets/vantagens-agilizar.svg";
import comoFunciona from "../../assets/analytics-imagem.svg";
// import campanhas from "../../assets/Imagem02_analytics.png";

const Analytics = () => {
  const handleSaibaMaisClick = () => {
    const saibaMaisSection = document.getElementById("saiba-mais");

    if (saibaMaisSection) {
      saibaMaisSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleWhatsAppClick = () => {
    const phoneNumber = "5511950853443";
    window.location.href = `https://api.whatsapp.com/send?phone=${phoneNumber}`;
  };

  return (
    <AnalyticsContainer>
      <QuemSomosContainer id="quem-somos">
        <div>
          <div className="div-right">
            <img src={analytics} alt="analytics" />
            <h1>Nosso Software de captação de dados no PDV</h1>
            <p>
              Uma das maiores dores do Trade Marketing é não saber se seu
              investimento no PDV teve o retorno esperado e desejado para
              campanha. Com o iFollow Analytics você pode cruzar dados da
              atividade do seu ponto de venda e ter essa informação na palma da
              mão.
            </p>
            <button onClick={handleSaibaMaisClick}>Saiba mais {">"}</button>
          </div>
        </div>
      </QuemSomosContainer>

      <SaibaMaisContainer id="saiba-mais">
        <div>
          <div className="content-left">
            <h1>Como funciona ?</h1>
          </div>

          <div className="content-right">
            <p>
              O iFollow Analytics é um Software de visão computacional baseado
              em inteligência artificial que produz métricas com base na
              atividade de um local. Através de uma câmera, ocorre a captação de
              imagens do local que são convertidas para dados em tempo real.
              Esses dados não sensíveis são enviados para a nuvem, gerando
              relatórios pelo software.
            </p>
          </div>
        </div>

        <div className="item">
          <img src={comoFunciona} alt="comoFunciona" />
        </div>
      </SaibaMaisContainer>

      <InformaçõesContainer id="saiba-mais">
        <div>
          <div className="content-left">
            <h1>Quais informações são coletadas ?</h1>
          </div>

          <div className="content-right">
            <p>
              A LGPD - Lei Geral de Proteção de Dados Pessoais - está totalmente
              coberta, pois não armazenamos nenhum tipo de dado sensível do
              shopper. O software utiliza a imagem captada pela câmera em tempo
              real para gerar apenas dados não sensíveis.
            </p>
          </div>
        </div>
        <div className=" card">
          <div className="item">
            <img src={trafegoPessoas} alt="Trafego pessoas" />
            <p>Tráfego de pessoas pelo ponto de venda</p>
          </div>
          <div className="item">
            <img src={relogio} alt="Trafego pessoas" />
            <p>Atividade em horários específicos</p>
          </div>
          <div className="item">
            <img src={faixaEtaria} alt="Trafego pessoas" />
            <p>Faixa etária aproximada e gênero</p>
          </div>
          <div className="item">
            <img src={tempo} alt="Trafego pessoas" />
            <p>Tempo de permanência na gôndola</p>
          </div>
          <div className="item">
            <img src={conversao} alt="Trafego pessoas" />
            <p>Conversão pela atenção</p>
          </div>
          <div className="item">
            <img src={tempoEspera} alt="Trafego pessoas" />
            <p>Tempo de espera em fila</p>
          </div>
          <div className="item">
            <img src={genero} alt="Trafego pessoas" />
            <p>Mapa de calor da atividade no local</p>
          </div>
          <div className="item">
            <img src={engajamento} alt="Trafego pessoas" />
            <p>Pessoas que se envolveram/interagiram</p>
          </div>
        </div>
      </InformaçõesContainer>

      <NossosNumerosContainer>
        <div className="header">
          <div className="vantagens">
            <h3>Quais as vantagens?</h3>

            <p style={{ fontStyle: "italic" }}>
              {" "}
              Mais destaque para a sua marca
            </p>
          </div>
          <p>
            Com o <b>iFollow Analytics</b> você tem acesso rápido e prático aos
            dados da atividade de seu ponto de venda.
          </p>
        </div>
        <div className=" card">
          <div className="item">
            <img src={alvo} alt="alvo" />
            <div>
              <h2>Campanhas direcionadas, assertivas e personalizadas</h2>
              <p>
                Conhecendo melhor seu público-alvo, é possível otimizar sua
                atividade no PDV, gerando mais resultado e vendas.
              </p>
            </div>
          </div>
          <div className="item">
            <img src={resultado} alt="resultado" />
            <div>
              <h2>Mensuração de resultado de campanha</h2>
              <p>
                Com as dashboards elaboradas pela coleta de dados, é possivel
                entender se a atividade no PDV atendeu às expectativas da
                campanha.
              </p>
            </div>
          </div>
          <div className="item">
            <img src={shopper} alt="shopper" />
            <div>
              <h2>Melhorar a experiência do shopper</h2>
              <p>
                Com o produto certo, no lugar certo e para a pessoa certa, a
                experiência de compra do shopper é aperfeiçoada e os resultados,
                cada vez melhores.
              </p>
            </div>
          </div>
          <div className="item">
            <img src={agilizar} alt="agilizar" />
            <div>
              <h2>Agilizar sua campanha e otimizar seu budget</h2>
              <p>
                Com todas as informações em mãos, planos de ação mais rápidos e
                assertivos otimizam cada vez mais o seu investimento.
              </p>
            </div>
          </div>
        </div>
      </NossosNumerosContainer>

      <ClientesContainer id="clientes">
        {/* <div className="left">
          <img src={campanhas} alt="campanhas" />
        </div> */}
        <div className="right">
          <div>
          <p>
            <b>
              Já imaginou essa ferramenta sendo utilizada em suas campanhas?
            </b>
            Os resultados e informações que você pode ter vão levar sua
            atividade nos PDVs para outro nível!{" "}
          </p>
          </div>

          <button onClick={handleWhatsAppClick}>quero conhecer {">"}</button>
        </div>
      </ClientesContainer>
    </AnalyticsContainer>
  );
};

export default Analytics;
