import Footer from "../../components/Footer";
import Header from "../../components/Header";
import ReactGA from 'react-ga';
import {useEffect} from 'react';
import { AnalyticsPageContainer } from "./style";
import Analytics from "../../components/Analytics";
import WhatsappComponent from "../../components/WhatsappComponent";

const AnalyticsPage = () =>{
    useEffect(()=>{
        ReactGA.pageview(window.location.pathname);
    },[])
    
    return (
        <AnalyticsPageContainer>
            <Header />
            <Analytics />
            <Footer />
            <WhatsappComponent />
        </AnalyticsPageContainer>
    )
};

export default AnalyticsPage;