import styled from "styled-components";
import bgCases from "../../assets/fundo_cases.webp";

export const CasesContainer = styled.div``;

export const ApresentacaoContainer = styled.div`
  background-image: url(${bgCases});
  background-color: #f26522;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 95vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 900px) {
    min-height: 90vh;
  }

  > .content {
    width: 75%;
    display: flex;
    flex-direction: column;
    margin-top: 80px;
    @media screen and (max-width: 900px) {
      width: 90%;
      align-items: center;
      justify-content: center;
      margin-top: 0px;
      text-align: center;
    }

    h1 {
      color: #fff;
      font-size: 1.9rem;
      margin-bottom: 20px;
      font-weight: 500;
    }
    p {
      color: #fff;
      font-weight: 200;
      font-size: 0.7rem;
      margin-top: 20px;
      margin-bottom: 20px;
      width: 40%;
      @media screen and (max-width: 900px) {
        width: 100%;
      }
    }
    b {
      color: #fff;
      font-size: 0.7rem;
      font-weight: 600;
    }

    a {
      color: #fff;
      font-size: 0.8rem;
      margin-top: 50px;
      text-decoration: none;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #ffff;
      width: 245px;

      img {
        width: 20px;
        margin: 10px;
      }
    }
  }
`;

export const ComoFuncionaContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 60px;
  min-height: 100vh;
  @media screen and (max-width: 900px) {
    flex-direction: column;
    width: 100%;
  }
  

  .divLeft {
    width: 30%;
    gap: 30px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    @media screen and (max-width: 900px) {
      display: none;
    }

    img {
      align-items: center;
      justify-content: center;
    }
  }
  .divRight {
    width: 28%;
    @media screen and (max-width: 900px) {
      width: 80%;
  }

    h2 {
      font-size: 1.4rem;
      color: #7c3999;
      font-weight: 600;
      margin-bottom: 20px;
      @media screen and (max-width: 900px) {
      text-align: center;
      font-size: 1.2rem;
  }
      
    }
    > div {
      width: 100%;
      img {
        width: 100%;
      }
      @media screen and (min-width: 900px) {
        display: none;
      }
    }

    p {
      font-size: 0.8rem;
      font-weight: 400;
      @media screen and (max-width: 900px) {
      text-align: center;
  }
    }
  }
`;

export const ProjetoCarnaval = styled.div`
  min-height: 100vh;
  background-color: #440056;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 80px;
  @media screen and (max-width: 900px) {
    flex-direction: column;
  }

  > .divRight {
    width: 30%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    @media screen and (max-width: 900px) {
    display: none;
  }

    > .imgBox {
      display: flex;
      gap: 30px;

      > img {
        width: 47%;
      }
    }
  }


  > .divLeft {
    width: 25%;
    @media screen and (max-width: 900px) {
      width: 80%;
      text-align: center;
  }


    h2 {
      font-size: 1.4rem;
      color: #fff;
      font-weight: 600;
      margin-bottom: 20px;
      @media screen and (max-width: 900px) {
        font-size: 1.3rem;
  }
    }
    p {
      color: #fff;
      font-size: 0.8rem;
      font-weight: 300;
    }

    > .divRightMobile {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-bottom: 20px;
    @media screen and (min-width: 900px) {
    display: none;
  }

    > .imgBoxMobile {
      display: flex;
      gap: 30px;

      > img {
        width: 45%;
      }
    }
  }
  }

`;
export const LiftLearnContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 85vh;
  @media screen and (max-width: 900px) {
    flex-direction: column;
  }


  .divLeft {
    width: 30%;
    gap: 30px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    @media screen and (max-width: 900px) {
      width:80%;
      align-items: center;
      
  }

    img {
      align-items: center;
      justify-content: center;
      width: 70%;
    }
    h2 {
      font-size: 1.2rem;
      color: #7c3999;
      font-weight: 600;
      margin-top: 20px;
      text-align: center;
      @media screen and (min-width: 900px) {
    display: none;
  }
    }
  }
  .divRight {
    width: 28%;
    @media screen and (max-width: 900px) {
      width:80%;
      align-items: center;
      text-align: center;
      margin-top: 20px;
  }

    h2 {
      font-size: 1.4rem;
      color: #7c3999;
      font-weight: 600;
      margin-bottom: 20px;
      @media screen and (max-width: 900px) {
    display: none;
  }
    }
    p {
      font-size: 0.8rem;
      font-weight: 400;
    }
  }
`;

export const ProjetoMars = styled.div`
  min-height: 100vh;
  background-color: #440056;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 80px;
  @media screen and (max-width: 900px) {
   flex-direction: column-reverse;
  }

  > .divRight {
    width: 30%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    @media screen and (max-width: 900px) {
      width: 80%;
  }

    > .imgBox {
      display: flex;
      gap: 30px;

      > img {
        width: 47%;
      }
    }
    h2 {
      font-size: 1.2rem;
      color: #fff;
      font-weight: 600;
      margin-top: 20px;
      text-align: center;
      @media screen and (min-width: 900px) {
   display: none;
  }
    }
  }
  > .divLeft {
    width: 25%;
    @media screen and (max-width: 900px) {
      width: 80%;
  }

    h2 {
      font-size: 1.4rem;
      color: #fff;
      font-weight: 600;
      margin-bottom: 20px;
      @media screen and (max-width: 900px) {
   display: none;
  }
    }
    p {
      color: #fff;
      font-size: 0.8rem;
      font-weight: 200;
      @media screen and (max-width: 900px) {
      text-align: center;
      margin-bottom: 50px;
  }
    }
  }
`;

export const ProjetoStopper = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 80px;
  @media screen and (max-width: 900px) {
      flex-direction: column;
      gap: 20px;
  }

  > .divLeft {
    width: 30%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    @media screen and (max-width: 900px) {
      width:80%;
      text-align: center;
  }

    > .imgBox {
      display: flex;
      gap: 30px;

      > img {
        width: 47%;
      }
    }
    h2 {
      font-size: 1.2rem;
      color: #7c3999;
      font-weight: 600;
      margin-top: 20px;
      text-align: center;
      @media screen and (min-width: 900px) {
      display: none;
  }
    }
  }
  > .divRight {
    width: 30%;
    @media screen and (max-width: 900px) {
      width:80%;
      text-align: center;
  }

    h2 {
      font-size: 1.4rem;
      color: #7c3999;
      font-weight: 600;
      margin-bottom: 20px;
      @media screen and (max-width: 900px) {
      display: none;
  }
    }
    p {
      font-size: 0.8rem;
      font-weight: 400;
    }
  }
`;

export const ProjetoAjinomoto = styled.div`
  min-height: 100vh;
  background-color: #440056;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 80px;
  @media screen and (max-width: 900px) {
      flex-direction: column-reverse;
      gap: 20px;
  }

  > .divRight {
    width: 30%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    @media screen and (max-width: 900px) {
      width: 80%;
  }

    > .imgBox {
      display: flex;
      gap: 30px;

      > img {
        width: 47%;
      }
    }

    h2 {
      font-size: 1.2rem;
      color: #ffff;
      font-weight: 600;
      margin-top: 20px;
      text-align: center;
      @media screen and (min-width: 900px) {
      display: none;
  }
}
    
  }
  > .divLeft {
    width: 28%;
    @media screen and (max-width: 900px) {
      width: 80%;
  }

    h2 {
      font-size: 1.4rem;
      color: #fff;
      font-weight: 600;
      margin-bottom: 20px;
      @media screen and (max-width: 900px) {
      display: none;
  }
    }
    p {
      color: #fff;
      font-size: 0.8rem;
      font-weight: 300;
      @media screen and (max-width: 900px) {
     text-align: center;
  }
    }
  }
`;

export const ProjetoTableTend = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 80px;
  @media screen and (max-width: 900px) {
      flex-direction: column;
      gap: 20px;
  }

  > .divLeft {
    width: 30%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    @media screen and (max-width: 900px) {
      width: 80%;
  }

    > .imgBox {
      display: flex;
      gap: 30px;

      > img {
        width: 47%;
      }
    }

    h2 {
      font-size: 1.2rem;
      color: #7c3999;
      font-weight: 600;
      margin-top: 20px;
      text-align: center;
      @media screen and (min-width: 900px) {
      display: none;
  }
    }
  }
  > .divRight {
    width: 28%;
    @media screen and (max-width: 900px) {
      width: 80%;
  }

    h2 {
      font-size: 1.4rem;
      color: #7c3999;
      font-weight: 600;
      margin-bottom: 20px;
      @media screen and (max-width: 900px) {
    display: none;
  }
    }
    p {
      font-size: 0.8rem;
      font-weight: 400;
      @media screen and (max-width: 900px) {
      text-align: center;
  }
    }
  }
`;

export const ProjetoGoose = styled.div`
  min-height: 100vh;
  background-color: #440056;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 80px;

  @media screen and (max-width: 900px) {
      flex-direction: column-reverse;
      gap: 20px;
    
  }

  > .divRight {
    width: 30%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    @media screen and (max-width: 900px) {
      width: 80%;
  }

    > .imgBox {
      display: flex;
      gap: 30px;

      > img {
        width: 47%;
      }
    }

    h2 {
      font-size: 1.2rem;
      color: #ffff;
      font-weight: 600;
      margin-top: 20px;
      text-align: center;
      @media screen and (min-width: 900px) {
      display: none;
  }
}
  }
  > .divLeft {
    width: 27%;
    @media screen and (max-width: 900px) {
      width: 80%;
  }

    h2 {
      font-size: 1.4rem;
      color: #fff;
      font-weight: 600;
      margin-bottom: 20px;
      @media screen and (max-width: 900px) {
      display: none;
  }
    }
    p {
      color: #fff;
      font-size: 0.8rem;
      font-weight: 300;
      @media screen and (max-width: 900px) {
      text-align: center;
      margin-bottom: 30px;
  }
    }
  }
`;

export const Solucoes = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
  min-height: 40vh;
  @media screen and (max-width: 900px) {
      display: none;
  }

  > .content {
    display: flex;
    width: 80%;
    gap: 30px;
    padding-bottom: 80px;
    border-bottom: 1px solid lightgray;

    > .content-left {
      width: 40%;

      h2 {
        font-size: 1.4rem;
        color: #732c92;
      }
    }
    > .content-right {
      width: 70%;

      p {
        font-size: 0.8rem;
        font-weight: 300;
        color: #707070;
      }
      a {
        color: #732c92;
        font-size: 0.8rem;
        margin-top: 20px;
        font-weight: 500;
        text-decoration: none;
        display: flex;
        align-items: center;
        width: 270px;
        gap: 10px;
        border-bottom: 1px solid #732c92;
      }
    }
  }
`;

export const EntreEmContatoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  margin-bottom: 30px;
 
  @media screen and (max-width: 1016px) {
    width: 90%;
    margin: 20px;
  }

  > .content {
    display: flex;
    gap: 50px;
    align-items: center;

    img {
      display: flex;
      width: 55%;
      margin-left: 30px;
      @media screen and (max-width: 1016px) {
        display: none;
      }
    }

    > .divInput {
      display: flex;
      flex-direction: column;
      width: 40%;
      margin-top:20px;
      
      @media screen and (max-width: 1016px) {
          width: 100%;
          margin-left: 0px;
        }
      

      h3{
        font-size: 0.8rem;
      }

      p {
        margin-bottom: 10px;
        font-size: 0.8rem;
      }

      label {
        margin-top: 20px;
      }

      input,textarea {
        width: 100%;
        padding: 10px;
        border-radius: 8px;
        border: none;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
        font-size: 0.8rem;

        ::placeholder {
          font-size: 0.7rem;
        }
      }
      textarea,input:focus {
        outline: none;
      }
      button {
        margin: 20px 0 0 0;
        border: none;
        background-color: rgb(0, 174, 239);
        padding: 10px;
        width: 65%;
        border-radius: 15px;
        font-size: 0.8rem;
        color: white;
        @media screen and (max-width: 1016px) {
          width: 100%;
        }
      }
    }
  }
`;
