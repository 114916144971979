import styled from "styled-components";

export const DigitalExperienceContainer = styled.div``;

export const ApresentacaoContainer = styled.div`
  background-color: #f26522;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  > .content {
    width: 80%;
    align-items: center;
    display: flex;
    justify-content: center;

    @media screen and (max-width: 900px) {
      flex-direction: column;
      text-align: center;
      width: 100%;
      margin-top: 160px;
      margin-bottom: -200px;
    }

    > .left {
      width: 50%;
      @media screen and (max-width: 900px) {
        width: 90%;
      }
      > h1 {
        font-size: 0.9rem;
        font-weight: 500;
        padding-bottom: 20px;
        color: white;

        @media screen and (max-width: 900px) {
          font-size: 1rem;
          font-weight: 400;
        }
      }

      > p {
        color: white;
        font-weight: 200;
        font-size: 0.8rem;
        margin-bottom: 20px;
      }
      .paragraph {
        font-weight: 200;
      }
      > img {
        margin-top: 15px;
        width: 70%;
        @media screen and (max-width: 900px) {
          width: 100%;

          margin-top: -40px;
        }
      }
      > button {
        margin: 20px 0 0 0;
        border: none;
        background-color: #732c92;
        padding: 18px;
        min-width: 55%;
        border-radius: 15px;
        font-size: 0.8rem;
        color: white;

        @media screen and (max-width: 900px) {
          width: 90%;
          margin-top: 50px;
          margin-bottom: 50px;
          padding: 28px;
        }
      }
    }
    > .right {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
      @media screen and (max-width: 900px) {
        width: 100%;
      }
    }

    > .right >div >img {
      margin-top: 80px;
      width: 80%;

      @media screen and (max-width: 900px) {
        margin-top: 15px;
        width: 80%;
      }
    }

    > div {
      width: 50%;
      @media screen and (max-width: 900px) {
        width: 90%;
      }
    }
  }

  > .como-funciona-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 150px;

    position: absolute;
    right: 25%;
    bottom: 15%;

    svg {
      width: 58px;
      margin-bottom: 4px;
    }

    &,
    &:visited,
    &:link,
    &:active {
      font-size: 0.73em;
      text-decoration: none;
    }

    &:hover {
      transform: scale(1.1);
      transition: transform 0.1s linear;
      color: #a43dd3;

      svg {
        path,
        polygon,
        rect,
        circle,
        ellipse {
          fill: #a43dd3;
        }
      }
    }
  }
`;

export const ComoFuncionaContainer = styled.div`
  min-height: 100vh;
  width: 100%;

  > .pdvs {
    display: flex;
    justify-content: center;
    @media screen and (max-width: 900px) {
      overflow: auto;
      justify-content: start;
    }

    > div {
      width: 18%;
      margin: 10px;
      margin-bottom: 50px;
      @media screen and (max-width: 900px) {
        width: 100%;
      }

      img {
        width: 100%;
        @media screen and (max-width: 900px) {
          width: 200px;
        }
      }
    }
  }

  > .white-container {
    background-color: #fff;
    height: 60%;
    padding: 50px 0;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 900px) {
      flex-direction: column;
      margin-top: 150px;
    }

    > h2 {
      font-size: 2.3rem;
      width: 43%;
      color: #732c92;
      @media screen and (max-width: 900px) {
        width: 90%;
        text-align: center;
        font-size: 1.7rem;
      }
    }

    > .content {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-between;
      color: #252525;
      font-weight: 300;
      text-align: center;
      height: 50%;
      width: 38%;
      text-align: start;
      @media screen and (max-width: 900px) {
        margin-top: 30px;
        width: 80%;
      }

      > p {
        font-size: 0.8rem;
        font-weight: 400;
        @media screen and (max-width: 900px) {
          text-align: center;
        }
      }

      span {
        font-weight: 800;
        font-size: 0.8rem;
      }

      img {
        width: 100px;
      }
    }
  }

  > .purple-container {
    background-color: white;
    height: 60%;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 50px;
    @media screen and (max-width: 900px) {
      flex-direction: column;
      padding: 5px;
    }

    > .description-container {
      background-color: #fff;
      height: 90%;
      padding: 50px 0;
      display: flex;
      width: 40%;
      justify-content: center;
      align-items: center;
      @media screen and (max-width: 900px) {
        width: 90%;
      }

      > div {
        display: flex;
        flex-direction: column;
        h2 {
          font-size: 1.7rem;
          color: #732c92;
          @media screen and (max-width: 900px) {
            text-align: center;
            font-size: 1.2rem;
            margin-bottom: 10px;
          }
        }
        p {
          font-size: 0.8rem;
          @media screen and (max-width: 900px) {
            text-align: center;
          }
        }
      }
    }

    > .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 60%;
      padding: 50px;
      @media screen and (max-width: 900px) {
        width: 90%;
        text-align: center;
        padding: 0px;
      }

      > p {
        font-size: 0.8rem;
        font-weight: 300;
        > span {
          font-size: 0.8rem;
          font-weight: 600;
        }
      }
    }
  }
`;

export const Links = styled.div`
  display: flex;
  align-items: center;
    justify-content: center;
  gap: 15px;
  .link {
    text-decoration: none;
    border: none;
    width: 20px;
    > button {
      width: 10px;
      padding: 5px;
      border: none;
      background-color: lightgray;
      margin-bottom: 50px;
      border-radius: 5px;
      cursor: pointer;

      &:focus {
        background-color: #F26522;
      }
      :hover {
        background-color: #F26522;
      }
    }

    @media screen and (min-width: 950px) {
      display: none;
    }
  }
  `

export const ImagesContainer = styled.div`
  width: 100%;

  .body-left {
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
    padding: 50px;
    gap: 50px;
    @media screen and (max-width: 900px) {
      flex-direction: column;
      padding: 0px;
    }
    > .content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: start;
      width: 35%;
      img{
        width: 35px;
      }
      @media screen and (max-width: 900px) {
        width: 100%;
        padding: 50px;
        justify-content: center;

        img {
          display: none;
        }
      }
    }

    > .left {
      display: flex;
      width: 40%;


      @media screen and (max-width: 900px) {
        width: 100%;
        margin-top: 30px;
      }
      img {
        width: 100%;
      }
    }

    > .right {
      display: flex;
      width: 40%;
      img {
        width: 85%;
      }
    }
  }

  > .body {
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
    padding: 50px;
    @media screen and (max-width: 900px) {
      flex-direction: column-reverse;
      padding: 0px;
    }

    > .content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: start;
      width: 35%;
      img{
        width: 35px;
      }
      @media screen and (max-width: 900px) {
        width: 100%;
        padding: 50px;
        justify-content: center;

        img {
          display: none;
        }
      }
    }

    > .right {
      display: flex;
      width: 40%;
      @media screen and (max-width: 900px) {
        width: 100%;
        margin-top: 30px;
      }
      img {
        width: 100%;
      }
    }

    > .left {
      display: flex;
      width: 40%;
      img {
        width: 85%;
      }
    }
  }
  > .body > .content > h2 {
    color: #a43dd3;
    font-size: 1rem;
    width: 80%;
    @media screen and (max-width: 900px) {
      width: 100%;
      font-weight: 500;
    }
  }

  > .body > .content > p {
    color: #252525;
    font-size: 0.8em;
    width: 80%;
    @media screen and (max-width: 900px) {
      font-size: 0.9em;
    }
  }

  > .body-left > .content > h2 {
    color: #a43dd3;
    font-size: 1rem;
    width: 80%;
    @media screen and (max-width: 900px) {
      width: 100%;
      font-weight: 500;
    }
  }

  > .body-left > .content > p {
    color: #252525;
    font-size: 0.8em;
    width: 80%;
    @media screen and (max-width: 900px) {
      font-size: 0.9em;
    }
  }
`;

export const PrincipaisVantagensContainer = styled.div`
  background-color: #f26522;
  min-height: 100vh;
  width: 100%;
  padding: 50px 0;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 900px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .container {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    row-gap: 20px;
    @media screen and (max-width: 900px) {
      flex-direction: column;
      text-align: center;
    }

    > .left {
      width: 30%;
      @media screen and (max-width: 900px) {
        width: 90%;
      }

      > h1 {
        color: white;
        margin-bottom: 40px;
        font-size: 1.5rem;
        font-weight: normal;
        text-align: justify;
        max-width: 800px;
        @media screen and (max-width: 900px) {
          text-align: center;
          font-size: 1.4rem;
        }
      }
      p {
        font-size: 0.8rem;
        color: white;
        @media screen and (max-width: 900px) {
          font-weight: 300;
        }
      }
    }
    > .right {
      width: 50%;
      @media screen and (max-width: 900px) {
        width: 100%;
      }
      img {
        width: 100%;
      }
    }
  }
  > .body {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    @media screen and (max-width: 900px) {
      justify-content: center;
      align-items: center;
      width: 90%;
    }

    > h2 {
      color: white;
      margin-left: 7%;
      text-align: start;
      font-size: 1.5rem;
      @media screen and (max-width: 900px) {
        font-weight: 500;
        font-size: 1.3rem;
        text-align: center;
        margin-left: 0;
      }
    }

    > span {
      color: white;
      margin-left: 7%;
      text-align: start;
      @media screen and (max-width: 900px) {
        text-align: center;
        margin-top: 10px;
        margin-left: 0;
        font-weight: 200;
        font-style: italic;
        font-size: 0.9rem;
      }
    }

    > .card {
      margin-top: 50px;
      display: flex;
      justify-content: center;
      gap: 30px;
      margin-left: 2%;
      @media screen and (max-width: 900px) {
        flex-direction: column;
        align-items: center;
        gap: 5px;
      }
      > div {
        padding: 2%;
        display: flex;
        align-items: center;
        background-color: white;
        width: 20%;
        border-radius: 10px;
        margin-bottom: 100px;
        @media screen and (max-width: 900px) {
          min-height: 100px;
          align-items: center;
          justify-content: center;
          width: 80%;
          padding: 10px 50px 10px 50px;
          margin-bottom: 15px;
        }

        > img {
          width: 40px;
          margin-right: 20px;
        }

        > p {
          color: black;
          font-size: 0.6rem;
          @media screen and (max-width: 900px) {
            font-size: 0.7rem;
          }
        }
      }
    }
  }
`;

export const ContainerScroll = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 80px;
  @media screen and (max-width: 900px) {
    padding: 0px;
  }

  > .container {
    width: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 80px;
    @media screen and (max-width: 900px) {
      gap: 20px;
    }
  }

  > .image-Content {
    display: flex;
    align-items: center;
    justify-content: center;

    > div {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 80px;

      img {
        margin-left: 50px;
        width: 350px;
      }
    }
  }
  > .container {
    display: flex;
    position: relative;
    align-items: center;
    width: 100%;
    padding: 10px;
    min-height: 50vh;
    @media screen and (max-width: 900px) {
      flex-direction: column;
    }

    > .left {
      width: 30%;
      @media screen and (max-width: 900px) {
        width: 90%;
        text-align: center;
        padding: 10px;
        margin-top: 10px;
      }

      h2 {
        font-weight: 400;
        font-size: 0.8rem;
      }

      a {
        color: #732c92;
        margin-top: 20px;
        font-size: 0.8rem;
      }
      .especialista {
        color: #732c92;
        margin-top: 20px;
        font-size: 0.8rem;
        background-color: transparent;
        border: none;
        border-bottom: 1px solid #732c92;
      }
    }

    ::before {
      position: absolute;
      content: "";
      right: 25px;
      top: 0;
      z-index: 99;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: auto;
      width: 160px;
      background-image: linear-gradient(-90deg, #fff, transparent);
      @media screen and (max-width: 900px) {
        right: 2px;
        top: 250px;
        z-index: 99;
        height: 38%;
      }
    }

    .box {
      position: relative;
      width: 58%;
      overflow-x: scroll;
      @media screen and (max-width: 900px) {
        position: relative;
        width: 100%;
        overflow-x: scroll;
        height: 150px;
      }

      ::-webkit-scrollbar {
        width: 0px;
        background-color: transparent;
        height: 10px;
      }

      ::-webkit-scrollbar-track {
        background-color: transparent;
      }

      ::-webkit-scrollbar-thumb {
        background-color: transparent;
        border-radius: 6px;
      }

      ::-webkit-scrollbar-thumb:hover {
        background-color: #f26522;
      }
    }

    .image-right {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      max-height: 500px;

      @media screen and (max-width: 900px) {
        width: 100%;
        height: 100%;
      }

      > div {
        display: flex;
        /* position: absolute; */

        > img {
          padding: 8px;
          width: 39%;
          @media screen and (max-width: 1140px) {
            width: 35%;
          }
        }
      }
    }
  }
`;

export const Solucoes = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40vh;

  > .content {
    display: flex;
    width: 80%;
    gap: 30px;
    padding-bottom: 80px;
    border-bottom: 1px solid lightgray;
    @media screen and (max-width: 900px) {
      flex-direction: column;
      text-align: center;
    }

    > .content-left {
      width: 35%;
      @media screen and (max-width: 900px) {
        width: 100%;
        margin-top: 20px;
        text-align: center;
      }

      h2 {
        font-size: 1.4rem;
        color: #732c92;
   
        @media screen and (max-width: 900px) {
          font-size: 1.3rem;
          color: #732c92;
          font-weight: 600;
        }
      }
      
    }
    > .content-right {
      width: 70%;
      @media screen and (max-width: 900px) {
        width: 100%;
      }

      p {
        font-size: 0.8rem;
        font-weight: 400;
        span {
          font-size: 0.8rem;
          font-weight: 600;
        }
      }
      a {
        color: #732c92;
        font-size: 0.8rem;
        margin-top: 20px;
             align-items: center;
        display: flex;
        text-decoration: none;
        border-bottom:1px solid #732c92;
        width: 240px;
      }
    }
  }
`;

export const EntreEmContatoContainer = styled.div`
  width: 100%;
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  margin: 10px;

  @media screen and (max-width: 1016px) {
    width: 90%;
    margin: 20px;
  }

  > .content {
    display: flex;
    gap: 50px;
    align-items: center;

    img {
      display: flex;
      width: 55%;

      @media screen and (max-width: 1016px) {
        display: none;
      }
    }

    > .divInput {
      display: flex;
      flex-direction: column;

      h3 {
        font-size: 0.8rem;
      }

      p {
        margin-bottom: 10px;
        font-size: 0.8rem;
      }

      label {
        margin-top: 20px;
      }

      input,
      textarea {
        width: 100%;
        padding: 10px;
        border-radius: 8px;
        border: none;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
        font-size: 0.8rem;

        ::placeholder {
          font-size: 0.7rem;
        }
      }
      textarea,
      input:focus {
        outline: none;
      }

      button {
        margin: 20px 0 0 0;
        border: none;
        background-color: rgb(0, 174, 239);
        padding: 12px;
        width: 60%;
        border-radius: 15px;
        font-size: 0.8rem;
        color: white;
        @media screen and (max-width: 1016px) {
          width: 100%;
        }
      }
    }
  }
`;
