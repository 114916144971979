import Footer from "../../components/Footer";
import Header from "../../components/Header";
import ReactGA from 'react-ga';
import {useEffect} from 'react';
import { CasesPageContainer } from "./style";
import Cases from "../../components/Cases";
import WhatsappComponent from "../../components/WhatsappComponent";

const CasesPage = () =>{
    useEffect(()=>{
        ReactGA.pageview(window.location.pathname);
    },[])
    
    return (
        <CasesPageContainer>
            <Header />
            <Cases/>
            <Footer />
            <WhatsappComponent />
        </CasesPageContainer>
    )
};

export default CasesPage;