import styled from "styled-components";

export const ImanageContainer = styled.div``;

export const ApresentacaoContainer = styled.div`
  background-color: #732c92;
  background-size: cover;
  background-position-x: -1px;
  height: 90vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;


  > .content-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    @media screen and (min-width: 900px) {
      margin-top: 70px;
    }

    img {
      width: 100%;
    }

    > .content {
      width: 34%;

      position: relative;

      > h1 {
        font-weight: 500;
        font-size: 0.9rem;
        color: white;
      }

      > p {
        font-weight: 200;
        font-size: 0.8em;
        color: white;
      }

      > p > b {
        font-weight: 600;
        font-size: 1em;
        color: white;
      }

      svg {
        width: 300px;
        margin: 30px 0 10px;
      }

      img {
        display: flex;

        @media screen and (min-width: 900px) {
          display: none;
        }
      }

      > button {
        margin: 25px 0 0 0;
        border: none;
        background-color: rgb(0, 174, 239);
        height: 68px;
        width: 330px;
        border-radius: 15px;
        font-size: 0.7rem;
        color: white;
      }
    }
  }

  .content-right {
    width: 50%;

    @media screen and (max-width: 900px) {
      img {
        display: none;
      }
    }
  }

  @media screen and (max-width: 900px) {
    height: 160vh;
    > .content-container {
      display: flex;
      width: 90%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    > .content-container > .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;

      h1 {
        font-size: 1.2rem;
      }
      p,
      b {
        font-size: 1rem;
      }
      button {
        font-size: 0.8rem;
        width: 100%;
      }
    }

  }


`;

export const SaibaMaisContainer = styled.div`
  min-height: 100vh;
  width: 100%;
  padding: 40px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: 115%;

  > .content-container {
    width: 80%;
    max-width: 1150px;
    @media screen and (max-width: 900px) {
      width: 90%;
    }

    > .box {
      padding: 0 98px;
      text-align: center;
      @media screen and (max-width: 900px) {
        padding: 10px;
      }

      > h3 {
        font-size: 1.9rem;
        text-align: center;
        color: #732c92;
        @media screen and (max-width: 900px) {
          font-size: 1.4rem;
        }
      }

      > p {
        margin-top: 25px;
        padding: 0 130px;
        font-size: 0.8rem;
        @media screen and (max-width: 900px) {
          padding: 10px;
          font-size: 0.9rem;
        }
      }
    }

    > .header {
      display: flex;
      align-items: center;
      gap: 50px;
      padding-bottom: 40px;
      border-bottom: solid 1px lightgray;
      margin-bottom: 50px;
      @media screen and (max-width: 900px) {
        flex-direction: column;
        text-align: center;
      }

      > .div-left {
        width: 70%;
        @media screen and (max-width: 900px) {
          width: 90%;
        }
        > h1 {
          width: 250px;
          color: #732c92;
          font-size: 2.3em;
          font-weight: 800;
          @media screen and (max-width: 900px) {
            font-size: 1.4rem;
            width: 100%;
          }
        }
      }

      > .div-right {
        > p {
          font-size: 0.8rem;
          text-align: start;
          font-weight: 300;
          @media screen and (max-width: 900px) {
            text-align: center;
          }

          > span {
            font-weight: 600;
            font-size: 0.8rem;
          }
        }
      }
    }

    > .body {
      display: flex;
      justify-content: space-between;
      margin-top: 40px;
      gap: 80px;

      > .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        text-align: center;
        border-radius: 10px;
        background-color: #fff;
        min-width: 260px;
        padding: 30px;

        > p {
          padding: 20px;
          color: #252525;
          font-size: 0.7rem;
        }

        img {
          width: 120px;
        }

        > a,
        > a:link,
        > a:visited,
        > a:active {
          text-decoration: underline;
          font-size: 0.7em;
        }
      }

      > .flow {
        border: 1px solid lightgray;
      }

      > .istock {
        border: 1px solid lightgray;
      }

      > .iwatch {
        border: 1px solid lightgray;
      }
    }
  }

  @media screen and (max-width: 1030px) {
    background-image: none;

    > .content-container > .body {
      flex-direction: column;
      align-items: center;
      row-gap: 20px;
    }

  }
`;

export const ComoFuncionaContainer = styled.div`
  span {
    font-size: 0.7rem;
    font-weight: 800;
  }

  > .purple-container {
    margin-top: 80px;
    padding: 30px 0;
    background-color: white;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    > .content {
      display: flex;
      row-gap: 25px;
      width: 83%;
      @media screen and (max-width: 900px) {
        flex-direction: column;
        text-align: center;
      }
    }

    > .content > div > h1 {
      width: 400px;
      font-size: 1.7rem;
      font-weight: 600;
      text-align: start;
      color: #732c92;
      @media screen and (max-width: 900px) {
        width: 100%;
      }
    }

    > .content > div > p {
      font-weight: 400;
      font-size: 0.7rem;
      font-style: italic;
      text-align: start;
      @media screen and (max-width: 900px) {
        text-align: center;
        font-size: 0.9rem;
      }
    }

    > .content > p {
      font-size: 0.8rem;
      text-align: start;
      @media screen and (max-width: 900px) {
        text-align: center;
        font-size: 0.9rem;
      }
    }
  }

  > .white-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 40px 90px;
    column-gap: 2%;
    row-gap: 20px;
    @media screen and (max-width: 900px) {
      flex-direction: column;
    }

    > .item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 31%;
      padding: 20px;
      min-height: 300px;
      @media screen and (max-width: 900px) {
        min-height: 160px;
      }

      > div {
        @media screen and (max-width: 900px) {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        svg {
          height: 40px;
          width: 50px;
          @media screen and (max-width: 900px) {
            height: 70px;
          }
        }
      }

      > h2 {
        color: #732c92;
        font-weight: 600;
        font-size: 0.9rem;
        @media screen and (max-width: 900px) {
          text-align: center;
          font-size: 1rem;
        }
      }

      > p {
        color: #000;
        font-size: 0.7rem;
        text-align: start;
        @media screen and (max-width: 900px) {
          display: none;
        }
      }
    }

    .faleConosco {
      color: #732c92;
      margin-top: 20px;
      font-size: 0.8rem;
      background-color: transparent;
      border: none;
      border-bottom: 1px solid #732c92;
    }

    > p {
      font-size: 0.8rem;
      text-align: start;
      @media screen and (max-width: 900px) {
        text-align: center;
      }
      a {
        color: #732c92;
        font-size: 0.8rem;
      }
    }
    > .processo {
      width: 90%;
      display: flex;
      align-items: center;
      justify-content: center;
      @media screen and (max-width: 900px) {
        overflow-x: scroll;
        width: 80%;
        justify-content:start;
      }

      > img {
        @media screen and (min-width: 900px) {
          width: 90%;
        }
      }
    }
  }

  @media screen and (max-width: 1060px) {
    > .white-container {
      padding: 40px 5%;
      column-gap: 4%;
      > .item {
        width: 100%;
      }
    }
  }

  /* @media screen and (max-width: 900px) {
    .purple-container > .content > h1 {
      font-size: 1.5em;
    }

    > .purple-container > .content > p {
      font-size: 0.8em;
    }
  }

  @media screen and (max-width: 660px) {
    > .white-container {
      padding: 40px 5%;
      column-gap: 4%;
      > .item {
        width: 47%;

        > div {
          svg {
            height: 30px;
            width: 40px;
          }
        }

        > h2 {
          font-size: 0.9em;
        }

        > p {
          color: #000;
          font-size: 0.8em;
        }
      }
    }
  } */
`;

export const FlowContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 900px) {
    align-items: center;
    justify-content: center;
  }

  > .descricao-container {
    display: flex;
    background: #13aae2;
    padding: 80px 0 0 0;

    > .left {
      display: flex;
      flex-direction: column;
      padding-left: 70px;
      row-gap: 20px;
      width: 70%;
      @media screen and (max-width: 900px) {
        width: 100%;
        padding: 20px;
      }
      @media screen and (min-width: 900px) {
        img {
          display: none;
        }
      }

      > img {
        @media screen and (max-width: 900px) {
          width: 90%;
          padding-left: 0px;
        }
      }

      svg {
        width: 200px;
        height: 100px;
      }
      > p {
        color: white;
        font-size: 0.7rem;
        font-weight: 200;
        @media screen and (max-width: 900px) {
          font-size: 0.9rem;
        }
      }
    }
    span {
      font-size: 0.7rem;
      color: white;
      font-weight: 500;
      @media screen and (max-width: 900px) {
        font-size: 0.9rem;
      }
    }
    b {
      font-size: 0.7rem;
      font-style: italic;
      color: white;
      font-weight: 300;
      @media screen and (max-width: 900px) {
        font-size: 0.9rem;
      }
    }

    > .right {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;

      img {
        width: 100%;
      }
    }
  }

  > .vantagens-container {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    @media screen and (max-width: 900px) {
      align-items: center;
      justify-content: center;
      width: 100%;
    }

    > div {
      margin-bottom: -60px;
      @media screen and (max-width: 900px) {
        margin-bottom: 60px;
      }
      > h3 {
        padding-left: 80px;
        color: white;
        font-size: 1.5rem;
        font-weight: 500;
        margin-top: 80px;
        @media screen and (max-width: 900px) {
          text-align: center;
          padding-left: 0px;
        }
      }
      > p {
        padding-left: 80px;
        font-style: italic;
        color: white;
        font-size: 0.9rem;
        font-weight: 300;
        @media screen and (max-width: 900px) {
          text-align: center;
          padding-left: 0px;
        }
      }
    }
    background-color: #13aae2;
    /* > div {
      display: flex;
      justify-content: center;
      column-gap: 40px;
      width: 100%;
      height: 90%;
      align-items: start; */

    .vantagens {
      display: flex;
      margin-top: auto;
      > h2 {
        margin-top: auto;
        color: #8338bc;
      }
    }

    .itens-container {
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 68px;
      position: relative;
      top: 67px;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 50px;
      width: 100%;
      @media screen and (max-width: 900px) {
        gap: 20px;
        top: -34px;
        width: 100%;
        padding: 0px;
      }

      .item {
        display: flex;
        background-color: white;
        padding: 15px;
        justify-content: center;
        align-items: center;
        text-align: justify;
        gap: 20px;
        width: 20%;
        border-radius: 12px;
        height: 100%;
        @media screen and (max-width: 900px) {
          width: 85%;
          padding: 20px;
        }
        > div {
          margin-top: auto;
          > div {
            svg {
              width: 30px;
              @media screen and (max-width: 900px) {
                width: 50px;
              }
            }
          }
        }

        > p {
          color: #0f0a15;
          font-size: 0.7em;
          text-align: start;
          @media screen and (max-width: 900px) {
            font-size: 0.8em;
          }
        }
      }
    }
  }

  @media screen and (max-width: 900px) {
    > .descricao-container {
      text-align: center;
      > .left {
        width: 100%;
      }
      > .right {
        display: none;
      }
    }
    > .vantagens-container {
      > div {
        column-gap: 30px;
        > .vantagens {
          > h2 {
            font-size: 0.8em;
          }
        }

        > .itens-container {
          column-gap: 30px;

          > .item {
            max-width: 300px;
            > div {
              > div {
                svg {
                  width: 50px;
                }
              }
            }

            > p {
              font-size: 0.7em;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 900px) {
    > .vantagens-container {
      > div {
        column-gap: 20px;
        > .vantagens {
          margin: auto 0;

          > h2 {
          }
        }

        > .itens-container {
          flex-wrap: wrap;
          column-gap: 20px;

          > .item {
            max-width: 300px;
            width: 48%;
            height: initial;
          }
        }
      }
    }
  }

  @media screen and (max-width: 900px) {
    > .vantagens-container {
      > div {
        flex-direction: column;
        column-gap: 20px;
        > .vantagens {
          justify-content: center;
          > h2 {
          }
        }

        > .itens-container {
          flex-wrap: wrap;
          column-gap: 20px;

          > .item {
            max-width: 300px;
            width: 48%;
            height: initial;
          }
        }
      }
    }
  }
`;

export const IstockContainer = styled.div`
  display: flex;
  flex-direction: column;

  > .descricao-container {
    display: flex;
    background: #00cc99;
    height: 100vh;
    align-items: center;
    @media screen and (max-width: 900px) {
      height: 100%;
    }

    > .left {
      justify-content: center;
      align-items: center;
      width: 62%;

      img {
        position: relative;
        top: 100px;
        left: 3%;
        right: 0;
        bottom: 0;
        width: 100%;
      }
    }

    > .right {
      margin-top: 70px;
      margin-left: -20px;
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      width: 36%;
      justify-content: center;

      > img {
        @media screen and (min-width: 900px) {
          display: none;
        }
      }

      > p {
        color: white;
        font-weight: 200;
        font-size: 0.7rem;
        > span {
          font-size: 0.7rem;
          color: white;
          font-weight: 500;
        }
      }

      svg {
        width: 200px;
        height: 100px;
      }
    }
  }

  > .vantagens-container {
    > .box {
      display: flex;
      flex-direction: column;
      align-items: end;
      margin-bottom: -100px;

      @media screen and (max-width: 900px) {
        align-items: center;
        justify-content: center;
        margin-bottom: none;
      }

      > h2 {
        color: white;
        font-size: 1.5rem;
        font-weight: 500;
        width: 40%;

        @media screen and (max-width: 900px) {
          text-align: center;
          width: 100%;
        }
      }
      > p {
        font-weight: 300;
        font-size: 0.8rem;
        font-style: italic;
        color: white;
        width: 40%;
        @media screen and (max-width: 900px) {
          text-align: center;
          width: 100%;
        }
      }
    }
    > div {
      display: flex;
      justify-content: center;
      height: 90%;
      background-color: #00cc99;

      > .vantagens {
        display: flex;
        margin-top: auto;
        > h2 {
          margin-top: auto;
          color: #8338bc;
        }
      }

      > .itens-container {
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 90px;
        position: relative;
        top: 124px;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 50px;
        width: 100%;

        > .item {
          display: flex;
          background-color: white;
          padding: 15px;
          justify-content: center;
          align-items: center;
          text-align: justify;
          gap: 20px;
          width: 20%;
          border-radius: 12px;
          height: 100%;
          > div {
            margin-top: auto;
            > div {
              svg {
                width: 30px;
              }
            }
          }

          > p {
            color: #0f0a15;
            font-size: 0.7em;
            text-align: start;
          }
        }
      }
    }
  }

  @media screen and (max-width: 900px) {
    > .descricao-container {
      text-align: center;
      justify-content: center;
      align-items: center;

      > .left {
        display: none;
      }
      > .right {
        width: 85%;
      }
    }

    > .vantagens-container {
      background-color: #00cc99;
      > div {
        column-gap: 0px;
        > .vantagens {
          > h2 {
            font-size: 0.8em;
          }
        }

        > .itens-container {
          background-color: #00cc99;
          width: 100%;
          flex-direction: column;
          padding: 0px;
          padding-bottom: 45px;

          > .item {
            width: 85%;
            margin-bottom: 15px;

            > div {
              > div {
                svg {
                  width: 50px;
                }
              }
            }

            > p {
              font-size: 0.7em;
            }
          }
        }
      }
    }
  }

  /* @media screen and (max-width: 865px) {
    > .vantagens-container {
      > div {
        column-gap: 20px;
        > .vantagens {
          margin: auto 0;

          > h2 {
          }
        }

        > .itens-container {
          flex-wrap: wrap;
          column-gap: 20px;

          > .item {
            max-width: 300px;
            width: 48%;
            height: initial;
          }
        }
      }
    }
  }

  @media screen and (max-width: 695px) {
    > .vantagens-container {
      > div {
        flex-direction: column;
        column-gap: 20px;
        > .vantagens {
          justify-content: center;
          > h2 {
          }
        }

        > .itens-container {
          flex-wrap: wrap;
          column-gap: 20px;

          > .item {
            max-width: 300px;
            width: 48%;
            height: initial;
          }
        }
      }
    }
  } */
`;

export const IwatchContainer = styled.div`
  display: flex;
  flex-direction: column;

  > .descricao-container {
    display: flex;
    background: #5d0180;
    padding: 40px 5%;
    padding-top: 80px;

    > .left {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      width: 40%;
      margin-top: 90px;
      @media screen and (min-width: 900px) {
        > img {
          display: none;
        }
      }

      > p {
        color: white;
        font-weight: 200;
        font-size: 0.7rem;
        > span {
          font-weight: 500;
          font-size: 0.7rem;
          color: white;
        }
      }

      svg {
        width: 200px;
        height: 100px;
      }
    }

    > .right {
      width: 55%;
      margin-top: 100px;

      img {
        width: 800px;
        margin-left: -83px;
        @media screen and (max-width: 1180px) {
          margin-left: 0px;
          width: 550px;
        }
      }
    }
  }

  > .vantagens-container {
    background-color: #5d0180;
    margin-bottom: -134px;
    > div {
      display: flex;
      flex-direction: column;
    }
    > div > h3 {
      padding-left: 80px;
      color: white;
      font-size: 1.5rem;
    }
    > div > p {
      padding-left: 80px;
      font-style: italic;
      color: white;
      font-size: 0.7rem;

      > span {
        font-size: 0.8rem;
      }
    }

    > div {
      display: flex;
      justify-content: center;
      column-gap: 40px;
      width: 100%;
      height: 90%;

      > .vantagens {
        display: flex;
        margin-top: auto;
        > h2 {
          margin-top: auto;
          color: #8338bc;
        }
      }

      > .itens-container {
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 70px;
        position: relative;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 50px;

        > .item {
          display: flex;
          background-color: white;
          padding: 15px;
          justify-content: center;
          align-items: center;
          text-align: justify;
          gap: 20px;
          width: 20%;
          border-radius: 12px;
          height: 100%;
          > div {
            margin-top: auto;
            > div {
              svg {
                width: 30px;
              }
            }
          }

          > p {
            color: #0f0a15;
            font-size: 0.7em;
            text-align: start;
          }
        }
      }
    }
  }

  @media screen and (max-width: 900px) {
    > .descricao-container {
      text-align: center;
      > .left {
        width: 100%;
      }
      > .right {
        display: none;
      }
    }

    > .vantagens-container {
      margin-bottom: 0px;
      > div {
        column-gap: 30px;
        > h3,
        p {
          text-align: center;
          padding-left: 0px;
        }
        > .vantagens {
          > h2 {
            font-size: 0.8em;
          }
        }

        > .itens-container {
          flex-direction: column;
          column-gap: 30px;
          width: 100%;
          padding: 0px;
          gap: 15px;
          margin-bottom: 40px;
          margin-top: 20px;

          > .item {
            min-width: 300px;
            width: 85%;
            > div {
              > div {
                svg {
                  width: 50px;
                }
              }
            }

            > p {
              font-size: 0.7em;
            }
          }
        }
      }
    }
  }

  /* @media screen and (max-width: 900px) {
    > .vantagens-container {
      > div {
        column-gap: 20px;
        > .vantagens {
          margin: auto 0;

          > h2 {
          }
        }

        > .itens-container {
          flex-wrap: wrap;
          column-gap: 20px;

          > .item {
            max-width: 300px;
            width: 48%;
            height: initial;
          }
        }
      }
    }
  } */

  /* @media screen and (max-width: 695px) {
    > .vantagens-container {
      > div {
        flex-direction: column;
        column-gap: 20px;
        > .vantagens {
          justify-content: center;
          > h2 {
          }
        }

        > .itens-container {
          flex-wrap: wrap;
          column-gap: 20px;

          > .item {
            max-width: 300px;
            width: 48%;
            height: initial;
          }
        }
      }
    }
  } */
`;

export const ComImanageContainer = styled.div`
  display: flex;
  flex-direction: column;

  > .descricao-container {
    display: flex;
    background: white;
    padding: 80px 5%;
    @media screen and (max-width: 900px) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    > .left {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      width: 55%;
      margin-top: 90px;
      @media screen and (max-width: 900px) {
        width: 90%;
        margin-top: 5px;
      }

      img {
        width: 100%;
      }
    }

    > .right {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 45%;
      margin-top: 90px;
      flex-direction: column;
      @media screen and (max-width: 900px) {
        width: 90%;
        margin-top: 10px;
      }

      > div > .queroConhecer {
        display: flex;
        flex-direction: column;

        > button {
          margin: 20px 0 0 0;
          border: none;
          background-color: rgb(0, 174, 239);
          padding: 15px;
          width: 50%;
          border-radius: 10px;
          font-size: 0.8rem;
          color: white;
          margin-top: 50px;
          @media screen and (max-width: 900px) {
            width: 100%;
          }
        }
        > i {
          font-size: 0.8rem;
        }
      }

      > div > p {
        font-weight: 400;
        font-size: 0.8rem;
        > span {
          font-weight: 600;
          font-size: 0.8rem;
        }
      }
      @media screen and (max-width: 1190px) {
        align-items: center;
        justify-content: center;
        width: 90%;
        text-align: center;
      }
    }
  }
`;
