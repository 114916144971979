import styled from "styled-components";

export const FooterContainer = styled.div`
  width: 100%;
  background-color: #732c92;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  > .content {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 90%;
    height: 50%;
    margin-top: 15px;

    > img {
      display: none;
    }

    @media screen and (max-width: 900px) {
      flex-direction: column;
      margin-top: 50px;
      align-items: start;
    }
  }

  > .content > .left {
    width: 25%;
    font-size: 0.7em;
    font-weight: 300;
    min-width: 280px;
    @media screen and (max-width: 900px) {
      width: 100%;
    }
  }

  > .content > .left {
    h1 {
      color: #00aeef;
      font-size: 1em;
      margin-bottom: 20px;
      @media screen and (max-width: 900px) {
        font-size: 0.9rem;
      }
    }
    p {
      color: white;
      font-size: 0.7rem;
      @media screen and (max-width: 900px) {
        font-size: 0.8rem;
      }
    }
  }

  > .content > .center {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    width: 40%;
    @media screen and (max-width: 900px) {
      width: 100%;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    p {
      font-size: 0.7rem;
      color: white;
      @media screen and (max-width: 900px) {
        font-size: 0.8rem;
      }
    }
  }

  > .content > .center > p {
    font-weight: 300;
  }

  > .content > .center > div {
    display: flex;
    column-gap: 10px;
  }

  > .content > .center > div > a svg {
    cursor: pointer;
  }

  > .content > .center > div > a svg:hover {
    opacity: 0.5;
  }

  > div .right {
    display: flex;
    flex-direction: column;
    width: 20%;
    min-width: 200px;
    @media screen and (max-width: 900px) {
      width: 100%;
    }

    p {
      font-weight: 500;
      color: white;
      font-size: 0.7rem;
      margin-bottom: 10px;
    }

    div {
      width: 25px;
      display: flex;
      gap: 20px;
    }
  }
  > .content > .right img {
    width: 100%;
  }

  > .footerCnpj {
    width: 86%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    justify-items: center;
    text-align: center;
    margin-top: 15px;
    @media screen and (max-width: 900px) {
      flex-direction: column;
      gap: 20px;
    }

    p {
      font-weight: 200;
      color: white;
      font-size: 0.7rem;
    }
  }

  > p {
    text-align: center;
    margin: 10px 0;
  }
`;
