import styled from "styled-components";

export const ClientesContainer = styled.div`
  width: 100%;
  min-height: 65vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f7f7;

  > img {
    width: 100%;
  }

  > .content > .divImg {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;

    .nossosCases {
      font-size: 0.8rem;
      color: #732c92;
      text-decoration: none;
      border-bottom: 1px solid #732c92;
      display: flex;
      align-items: center;
      font-weight: 600;
    }
  }



  > .content > .divImg > .divScroll {
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-bottom: 10px;

    > div {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      .item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 170px;
        height: 80px;

        img {
          width: 30%;
        }
      }
    }
  }

  > .content {
    display: flex;
    align-items: center;

    width: 90%;
    justify-content: center;

    > h1 {
      font-size: 1.8rem;
      color: #732c92;
    }
    > p {
      text-align: center;
      width: 350px;
      font-size: 1.4em;
      font-weight: 300;
      > b {
        font-size: 1em;
        color: #a43dd3;
      }
    }

    > .body {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      gap: 40px;
      padding: 0 10px;

      > figure {
        width: 120px;
        > div {
          width: 100%;
          > img {
            width: 100%;
          }
        }
      }
    }
  }

  @media screen and (max-width: 915px) {
    min-height: 60vh;
    .content {
      flex-direction: column;

      h1 {
        font-size: 1.5rem;
      }

      .divImg {
        width: 85%;
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 30px;

        .divScroll {
          align-items: start;
          overflow-y: scroll;
          div {
            flex-wrap: nowrap;
            height: 20vh;
            .item {
              width: 146px;
              img {
                width: 60%;
              }
            }
          }
        }
      }
    }
  }
`;

export const Links = styled.div`
  display: flex;
  gap: 15px;
  .link {
    text-decoration: none;
    border: none;
    > button {
      width: 10px;
      padding: 5px;
      border: none;
      background-color: lightgray;
      margin-bottom: 50px;
      border-radius: 5px;
     
      cursor: pointer;

      &:focus {
        background-color: #732c92;
      }
      :hover {
        background-color: #732c92;
      }
    }

    @media screen and (min-width: 950px) {
      display: none;
    }
  }
`;
