import {
  ApresentacaoContainer,
  EntreEmContatoContainer,
  ImagesContainer,
  SaibaMaisContainer,
  TailorDevContainer,
} from "./style";
import swift from "../../assets/swift.png";
import cardapio from "../../assets/cardapio.png";
import pdvPet from "../../assets/pdvPet.png";
import flecha from "../../assets/seta.png";
import tailorDevContato from "../../assets/tailorDevContato.png";
import { Link } from "react-router-dom";
import FormEmail from "../FormEmail";

const TailorDev = () => {
  const handleWhatsAppClick = () => {
    const phoneNumber = "5511950853443";
    window.location.href = `https://api.whatsapp.com/send?phone=${phoneNumber}`;
  };

  return (
    <TailorDevContainer>
      <ApresentacaoContainer>
        <div className="content">
          <h1>Tailor DEV</h1>
          <p>
            A iFollow é expert em pensar e criar sistemas e soluções à base de
            tecnologia para necessidades específicas de cada empresa que
            atendemos, isso é o que chamamos de <span>TAILOR DEV</span>, que são
            nossos projetos desenvolvidos para dores pontuais de nossos
            clientes.
          </p>

          <button onClick={handleWhatsAppClick}>
            Fale com um especialista {">"}
          </button>
        </div>
      </ApresentacaoContainer>

      <ImagesContainer>
        <div className="body">
          <div className="left">
            <img src={swift} alt="swift" />
          </div>
          <div className="content">
            <h2>Portal Swift</h2>
            <img src={swift} alt="swift" />

            <p>
              Desenvolvemos um sistema personalizado para nosso cliente SWIFT, a
              fim de{" "}
              <span>facilitar e otimizar a precificação em suas unidades!</span>
            </p>
            <br></br>
            <p>
              Como a marca possui uma vasta capilaridade de lojas, essa solução
              também auxilia na padronização de sua comunicação visual e, ainda
              sim, confere a cada unidade autonomia para atualizar seus preços
              de forma rápida e prática.{" "}
            </p>
            <br></br>
            <ul>
              <li>
                <p>- Dashboard de pedidos</p>
              </li>
              <li>
                <p>- Seleção dos produtos</p>
              </li>
              <li>
                <p>- Produção da arte automática em modelo oficial SWIFT</p>
              </li>
              <li>
                <p>- Impressão personalizada</p>
              </li>
            </ul>
          </div>
        </div>

        <div className="body">
          <div className="content">
            <h2>Gestor de Cardápio</h2>
            <img src={cardapio} alt="cardapio" />

            <p>
              O <span>Gestor de Cardápios</span> é um sistema que, com poucos
              cliques, dá total autonomia ao representante de vendas para fazer
              a criação e solicitação do menu que vai para o PDV. Personalizando
              o cardápio com o nome do estabelecimento, preços atuais e com a
              possibilidade de escolha do layout, é possível
              <span> gerar cardápios impressos e por QR-Code.</span>
            </p>
            <br></br>
            <p>
              O que antes era feito em diversas etapas{" "}
              {
                "(recebimento dos valores e produtos, criação indivídual de cada pedido e controle por planilhas)"
              }
              , hoje é feito com{" "}
              <span>
                alguns cliques e com total visão e gestão em um único lugar.
              </span>
            </p>
            <br></br>
            <p>
              Além de trazer a facilidade de criação ao representante{" "}
              {"(principalmente por ser multiplataforma)"}, traz uma maior visão
              do que está sendo feito para o administrador e controle do que
              está sendo impresso ao produtor.
            </p>
          </div>
          <div className="right">
            <img src={cardapio} alt="cardapio" />
          </div>
        </div>

        <div className="body">
          <div className="left">
            <img src={pdvPet} alt="cardapio" />
          </div>
          <div className="content">
            <h2>PDV Pet</h2>
            <img src={pdvPet} alt="cardapio" />

            <p>
              O <span>PDV PET</span> é um sistema de auditoria no ponto de venda
              através do nosso portal web ou pelo aplicativo. Funciona assim: Os coordenadores de promotoria criam os roteiros e ações a serem realizadas, e via aplicativo os promotores sobem a foto da execução, a qual passará por uma avaliação. Caso a foto seja reprovada, o promotor deverá subir uma nova foto com a execução correta. Caso a nova foto da execução seja aprovada, ela é considerada completa e ganha os pontos dessa ação/auditoria.
            </p>
            <br></br>
            <p>
              Tudo isso <span>gerenciado por dashboards em tempo real</span>,
              dando mais controle, visibilidade e garantindo a{" "}
              <span>
                execução correta das suas campanhas no ponto de venda!
              </span>
            </p>
          </div>
        </div>
      </ImagesContainer>

      <SaibaMaisContainer id="saiba-mais">
        <div className="content-left">
          <h1>Já pensou no que poderíamos te ajudar?</h1>
          <img src={tailorDevContato} alt="tailorDev" />
        </div>
        <div className="content-right">
          <div className="header">
            <p>
              Na iFollow, a gente pensa e cria soluções exclusivas para as
              necessidades singulares dos nossos clientes. Com muita atenção e
              dedicação, vamos entregar o projeto ideal para a sua necessidade!
            </p>

            <Link to="/quem-somos">
              Conheça nossa empresa <img src={flecha} alt="flecha" />
            </Link>
          </div>
        </div>
      </SaibaMaisContainer>

      <EntreEmContatoContainer id="clientes">
        <div className="content">
          <img src={tailorDevContato} alt="tailorDev" />
          <FormEmail />
        </div>
      </EntreEmContatoContainer>
    </TailorDevContainer>
  );
};

export default TailorDev;
