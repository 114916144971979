import {
  ApresentacaoContainer,
  ComImanageContainer,
  ComoFuncionaContainer,
  FlowContainer,
  ImanageContainer,
  IstockContainer,
  IwatchContainer,
  SaibaMaisContainer,
} from "./styles";
import { motion } from "framer-motion";
import ImanageBranco from "../../assets/imanage-logo.svg";
import processoImg from "../../assets/processo.png";
import imgImanage from "../../assets/imanage (1).svg";
import Flow from "../../assets/flow.webp";
import Istock from "../../assets/istock.webp";
import Iwatch from "../../assets/iwatch.webp";
import comImanage from "../../assets/comImanage.svg";
import LazyLoad from "react-lazyload";
import { ReactSVG } from "react-svg";
import EstoqueMaterialSvg from "../../assets/funciona-estoque.svg";
import LogisticaMaterialSvg from "../../assets/funciona-logistica.svg";
import PositivacaoAuditoriaSvg from "../../assets/funciona-positivação.svg";
import ProducaoMaterialSvg from "../../assets/funciona-produção.svg";
import SolicitacaoMaterialSvg from "../../assets/funciona-solicitação.svg";
import VidaUtilRastreioSvg from "../../assets/funciona-rastreio.svg";
import FlowSvg from "../../assets/flow.svg";
import IStockSvg from "../../assets/istock.svg";
import IWatchSvg from "../../assets/iwatch.svg";
import FlowImagem from "../../assets/flow.png";
import IStockImagem from "../../assets/istock.png";
import IWatchImagem from "../../assets/iwatch.png";
import FlowIcone01 from "../../assets/flow-icone01.svg";
import FlowIcone02 from "../../assets/flow-icone02.svg";
import FlowIcone03 from "../../assets/flow-icone03.svg";
import FlowIcone04 from "../../assets/flow-icone04.svg";
import IStockIcone01 from "../../assets/istock-icone01.svg";
import IStockIcone02 from "../../assets/istock-icone02.svg";
import IStockIcone03 from "../../assets/istock-icone03.svg";
import IStockIcone04 from "../../assets/istock-icone04.svg";
import IWatchIcone01 from "../../assets/iwatch-icone01.svg";
import IWatchIcone02 from "../../assets/iwatch-icone02.svg";
import IWatchIcone03 from "../../assets/iwatch-icone03.svg";
import IWatchIcone04 from "../../assets/iwatch-icone04.svg";
import { Link } from "react-router-dom";

const Imanage = () => {
  const handleWhatsAppClick = () => {
    const phoneNumber = "5511950853443";
    const message = encodeURIComponent(
      "Olá, Gostaria de saber mais sobre o iManage"
    );
    window.location.href = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${message}`;
  };
  return (
    <ImanageContainer>
      <ApresentacaoContainer>
        <div className="content-container">
          <div className="content">
            <h1>
              Todo o controle e organização para o Trade Marketing que sua
              empresa precisa
            </h1>
            <ReactSVG src={ImanageBranco} alt="imanage" />
            <img src={imgImanage} alt="imanage" />
            <p>
              Uma <b>solução inovadora e disruptiva</b> para auxiliar nossos
              clientes a se destacarem no mercado.
            </p>
            <button onClick={handleWhatsAppClick}>
              fale com um especialista {">"}
            </button>
          </div>
          <div className="content-right">
            <motion.div
              initial={{ x: "100%" }}
              animate={{ x: 0 }}
              exit={{ x: "100%" }}
              transition={{ duration: 1 }}
            >
              <img src={imgImanage} alt="imanage" />
            </motion.div>
          </div>
        </div>
      </ApresentacaoContainer>
      <SaibaMaisContainer id="saiba-mais">
        <div className="content-container">
          <div className="header">
            <div className="div-left">
              <h1>O que é o iManage?</h1>
            </div>
            <div className="div-right">
              <p>
                O iManage é um{" "}
                <span>
                  aplicativo-solução de gestão ponta a ponta de campanhas de
                  Trade Marketing
                </span>
                . Ele é dividido em 3 módulos que funcionam de forma
                independente e integrada, gerando{" "}
                <span>controle e visibilidade em tempo real</span> de todo o seu
                projeto ou partes dele:{" "}
                <span>produção, estoque, entrega, positivação e mais</span>
                (quando necessário, temos controle desses ativos durante toda
                sua vida útil em campo, <span>até a recolha</span>).
              </p>
            </div>
          </div>
          <div className="box">
            <h3>
              Mais tecnologia e gestão para o Trade Marketing na palma da sua
              mão.
            </h3>
            <p>
              O iManage oferece módulos que ajudam a trazer mais resultados,
              controle e praticidade de acordo com a sua necessidade.{" "}
            </p>
          </div>
          <div className="body">
            <div className="item flow">
              <LazyLoad>
                <img src={Flow} alt="flow" />
              </LazyLoad>
              <p>
                A possibilidade de rastrear as mercadorias por meio de dados de
                transporte e entrega.
              </p>
              <a href="#flow">{"saiba mais >"}</a>
            </div>
            <div className="item istock">
              <LazyLoad>
                <img src={Istock} alt="istock" />
              </LazyLoad>
              <p>
                A segurança da entrega, devolução e controle de seus materiais
                em estoque
              </p>
              <a href="#istock">{"saiba mais >"}</a>
            </div>
            <div className="item iwatch">
              <LazyLoad>
                <img src={Iwatch} alt="iwatch" />
              </LazyLoad>
              <p>
                O acompanhamento e gerenciamento remoto dos seus materias no
                ponto de venda
              </p>
              <a href="#iwatch">{"saiba mais >"}</a>
            </div>
          </div>
        </div>
      </SaibaMaisContainer>
      <ComoFuncionaContainer>
        <div className="purple-container">
          <div className="content">
            <div>
              <h1>Como funciona?</h1>

              <p>Entenda como o iManage é na pratica!</p>
            </div>
            <p>
              Acompanhamento em real-time de todo o processo de sua campanha,
              iniciando na produção do material, à logística, estoque e
              positivação. Além disso, é possível ainda acompanhar a vida útil
              do produto em campo, seu retorno ao estoque e solicitação de
              novos materiais.
            </p>
          </div>
        </div>
        <div className="white-container">
          <div className="item">
            <ReactSVG src={ProducaoMaterialSvg} />
            <h2>Produção do seu material</h2>
            <p>
              Com o <span>Flow</span> você acompanha desde a produção de seu
              material e seu transporte até o estoque ou positivação.
            </p>
          </div>
          <div className="item">
            <ReactSVG src={EstoqueMaterialSvg} />
            <h2>Estoque de material</h2>
            <p>
              O controle do seu estoque e de todas as movimentações estão no
              <span> iStock.</span>
            </p>
          </div>
          <div className="item">
            <ReactSVG src={SolicitacaoMaterialSvg} />
            <h2>Solicitação do material</h2>
            <p>
              Seu material já tem destino certo? Com as solicitações do{" "}
              <span>iStock </span>
              você acompanha desde a solicitação até a entrega.
            </p>
          </div>
          <div className="item">
            <ReactSVG src={LogisticaMaterialSvg} />
            <h2>Logística do material</h2>
            <p>
              Quer mais rapidez e segurança nas suas entregas? O{" "}
              <span>Flow</span> apresenta um gerenciamento dos seus ativos.
            </p>
          </div>
          <div className="item">
            <ReactSVG src={PositivacaoAuditoriaSvg} />
            <h2>Positivação / auditoria</h2>
            <p>
              Acompanhe a positivação e sua comprovação por fotos no{" "}
              <span>Flow</span> em tempo real, com todos os dados atualizados.
            </p>
          </div>
          <div className="item">
            <ReactSVG src={VidaUtilRastreioSvg} />
            <h2>Vida útil e rastreio</h2>
            <p>
              Gerencie seus ativos em campo com o <span>iWatch</span>, aumente
              sua vida útil e a possibilidade de seu retorno ao campo.
            </p>
          </div>
          <p>
            Os módulos do iManage funcionam de forma independente, mas quando
            combinados <span>acompanham sua campanha do início ao fim.</span>
          </p>
          <div className="processo">
            <img src={processoImg} alt="guarana"></img>
          </div>
          <p>
            Quer o iManage para sua empresa?{" "}
            <Link to="">
              <button onClick={handleWhatsAppClick} className="faleConosco">
                Fale conosco!
              </button>
            </Link>{" "}
          </p>
        </div>
      </ComoFuncionaContainer>
      <FlowContainer id="flow">
        <div className="descricao-container">
          <div className="left">
            <ReactSVG src={FlowSvg} />
            <img src={FlowImagem} alt="dispositivos" />
            <p>
              Este módulo do aplicativo foi elaborado para que você acompanhe a
              <span> produção de seu material</span> e seu{" "}
              <span>transporte</span> até o local de estoque ou da positivação.
            </p>
            <p>
              Depois, para que o momento da entrega seja mais seguro e rápido, o
              FLOW também oferece o <span>gerenciamento dos seus ativos</span>{" "}
              nesta etapa.
            </p>
            <p>
              Assim, com os materiais já entregues e prontos para serem
              positivados, o <span>acompanhamento da positivação</span> através
              de comprovação por fotos em tempo real também é possivel.
            </p>
            <b>
              Além da rapidez da visualização de cada entrega, você também tem a
              visão gerencial de toda a campanha através de nossas dashboards.
            </b>
          </div>

          <div className="right">
            <LazyLoad>
              <img src={FlowImagem} alt="dispositivos" />
            </LazyLoad>
          </div>
        </div>
        <div className="vantagens-container">
          <div>
            <h3>Como funciona ?</h3>
            <p>Produção, logística e positivação do material.</p>
          </div>

          <div className="itens-container">
            <div className="item">
              <ReactSVG src={FlowIcone01} />
              <p>Rastreabilidade de materiais em tempo real</p>
            </div>
            <div className="item">
              <ReactSVG src={FlowIcone02} />
              <p>Comprovante de entrega com upload de recebimento</p>
            </div>
            <div className="item">
              <ReactSVG src={FlowIcone03} />
              <p>Comprovação da ativação por meio de fotos</p>
            </div>
            <div className="item">
              <ReactSVG src={FlowIcone04} />
              <p>Avaliação dos operadores logísticos</p>
            </div>
          </div>
        </div>
      </FlowContainer>
      <IstockContainer id="istock">
        <div className="descricao-container">
          <div className="left">
            <LazyLoad>
              <img src={IStockImagem} alt="celulares" />
            </LazyLoad>
          </div>
          <div className="right">
            <ReactSVG src={IStockSvg} />
            <img src={IStockImagem} alt="celulares" />

            <p>
              Esse módulo do aplicativo foi elaborado para que você tenha{" "}
              <span>controle do seu estoque</span> e de todas as suas
              movimentações, assim como o<br></br>
              <span>
                acompanhamento de suas solicitações de material até as entregas
              </span>
              , para que cheguem e retornem ao destino com segurança.
            </p>
          </div>
        </div>

        <div className="vantagens-container">
          <div className="box">
            <h2>Como funciona?</h2>
            <p>Estoque e solicitação do material</p>
          </div>
          <div>
            <div className="vantagens"></div>
            <div className="itens-container">
              <div className="item">
                <ReactSVG src={IStockIcone01} />
                <p>Facilidade na solicitação de materiais</p>
              </div>
              <div className="item">
                <ReactSVG src={IStockIcone02} />
                <p>Visualização do status das solicitações</p>
              </div>
              <div className="item">
                <ReactSVG src={IStockIcone03} />
                <p>Controle do estoque de materiais</p>
              </div>
              <div className="item">
                <ReactSVG src={IStockIcone04} />
                <p>Dashboard fácil e descomplicada</p>
              </div>
            </div>
          </div>
        </div>
      </IstockContainer>
      <IwatchContainer id="iwatch">
        <div className="descricao-container">
          <div className="left">
            <ReactSVG src={IWatchSvg} />
            <img src={IWatchImagem} alt="dispositivo" />
            <p>
              Esse módulo foi desenvolvido para te proporcionar um{" "}
              <span>retorno sobre seu investimento</span> feito em cada
              material, através do{" "}
              <span>
                gerenciamento remoto e em tempo real dos seus ativos em campo
              </span>
              , como geladeiras, choppeiras, pontas de gôndola e muito mais.
            </p>

            <p>
              É possível monitorar informações como temperatura, umidade/PH,
              geolocalização, gastos de energia, frequência de abertura de
              portas, entre outras coisas, possibilitando a prevenção de riscos
              e a diminuição de manutenções,{" "}
              <span>
                aumentando a vida útil do material e as suas chances de voltar
                ao campo.
              </span>
            </p>
          </div>
          <div className="right">
            <LazyLoad>
              <img src={IWatchImagem} alt="dispositivo" />
            </LazyLoad>
          </div>
        </div>
        <div className="vantagens-container">
          <div>
            <h3>Como funciona ?</h3>
            <p>Gerenciamento do material ativo em campo</p>
          </div>
          <div>
            <div className="vantagens"></div>
            <div className="itens-container">
              <div className="item">
                <ReactSVG src={IWatchIcone01} />
                <p>Localização precisa em tempo real de cada ativo</p>
              </div>
              <div className="item">
                <ReactSVG src={IWatchIcone02} />
                <p>Maior eficiência e ROI dos seus materiais ativos</p>
              </div>
              <div className="item">
                <ReactSVG src={IWatchIcone03} />
                <p>Relatórios e BI com todos os seus materiais ativos</p>
              </div>
              <div className="item">
                <ReactSVG src={IWatchIcone04} />
                <p>Gerenciamento de informações customizadas</p>
              </div>
            </div>
          </div>
        </div>
      </IwatchContainer>
      <ComImanageContainer id="iwatch">
        <div className="descricao-container">
          <div className="left">
            <img src={comImanage} alt="imanage" />
          </div>
          <div className="right">
            <div>
              <p>
                Com o <span>iManage</span>, mais uma dor é sanada, ao
                entregarmos na palma de sua mão um{" "}
                <span>
                  aplicativo que te permite visualizar toda a gestão de sua
                  campanha de Trade Marketing
                </span>{" "}
                ou das partes que você necessitar.
              </p>
              <br></br>
              <div className="queroConhecer">
                <i>Controle e inovação estão mais perto do que nunca!</i>
                <button onClick={handleWhatsAppClick}>
                  Quero Conhecer {">"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </ComImanageContainer>
    </ImanageContainer>
  );
};

export default Imanage;
