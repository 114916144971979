import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`

    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Poppins';
        scroll-behavior: smooth;
        color: black;
    }
    button,
    a {
        cursor: pointer;
    }


`

export default GlobalStyle;