import styled from "styled-components";
import homeBg from "../../assets/fundo_Analytics.png";

export const AnalyticsContainer = styled.div`
  .div-saiba-mais {
    text-align: left;
    padding-top: 20px;

    > p {
      font-size: 0.7rem;
    }
  }
`;

export const QuemSomosContainer = styled.div`
  background-image: url(${homeBg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #080719;
  min-height: 120vh;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 900px) {
    background-position: right;
    min-height: 140vh;
  }

  > div {
    margin-bottom: 80px;
    min-height: 80vh;
    width: 80%;
    display: flex;
    align-items: end;
    justify-content: flex-end;
    gap: 20px;

    > .div-right {
      width: 43%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      @media screen and (max-width: 900px) {
        width: 100%;
        align-items: center;
      }
      @media screen and (max-width: 375px) {
        margin-top: 130px;
      }

      img {
        width: 75%;
      }

      > h1 {
        color: white;
        width: 515px;
        margin: 15px 0 15px 0;
        font-size: 0.9rem;
        margin-top: 40px;
        font-weight: 600;
        text-align: justify;
        @media screen and (max-width: 1100px) {
          width: 100%;
          font-size: 1rem;
          text-align: center;
        }

      }

      > p {
        font-size: 0.6rem;
        width: 400px;
        text-align: start;
        color: white;
        font-weight: 200;
        @media screen and (max-width: 900px) {
          width: 100%;
          font-size: 0.9rem;
          text-align: center;
        }
      }

      button {
        margin-top: 40px;
        border: none;
        background-color: rgb(0, 174, 239);
        padding: 15px;
        width: 65%;
        border-radius: 15px;
        font-size: 0.8rem;
        color: white;
        align-items: center;
        @media screen and (max-width: 900px) {
          width: 100%;
        }
      }
    }
  }
`;

export const SaibaMaisContainer = styled.div`
  background-size: cover;
  width: 100%;
  min-height: 20vh;
  margin-top: 45px;
  padding: 30px 0;
  display: flex;
  align-items: center;
  column-gap: 60px;
  flex-direction: column;
  > div {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    @media screen and (max-width: 900px) {
      flex-direction: column;
    }

    > .content-left {
      width: 35%;
      @media screen and (max-width: 900px) {
        width: 100%;
      }

      > h1 {
        font-size: 1.5rem;
        font-weight: 600;
        color: #732c92;
        @media screen and (max-width: 900px) {
          text-align: center;
          font-size: 1.4rem;
        }
      }

      > p {
        margin-bottom: 20px;
        font-size: 0.9em;
        width: 90%;
        @media screen and (max-width: 900px) {
          text-align: center;
          width: 100%;
        }
      }
    }

    > .content-right {
      width: 90%;
      display: flex;
      flex-direction: column;
      align-items: center;
      @media screen and (max-width: 900px) {
        text-align: center;
        width: 100%;
      }

      > p {
        font-size: 0.8rem;
        font-weight: 300;
        text-align: justify;
        @media screen and (max-width: 900px) {
          text-align: center;
          width: 100%;
        }
        > span {
          font-size: 0.9rem;
          font-weight: 500;
          font-style: italic;
        }
      }
    }
  }

  > .item {
    img {
      margin-top: 90px;
      width: 100%;
    }
  }
`;

export const InformaçõesContainer = styled.div`
  background-size: cover;
  width: 100%;
  min-height: 35vh;
  margin-top: 45px;
  padding: 30px 0;
  display: flex;
  align-items: center;
  column-gap: 60px;
  flex-direction: column;
  > div {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    margin-bottom: 8%;
    @media screen and (max-width: 900px) {
      flex-direction: column;
    }

    > .content-left {
      width: 35%;
      @media screen and (max-width: 900px) {
        width: 100%;
      }

      > h1 {
        font-size: 1.5rem;
        font-weight: 600;
        color: #732c92;
        @media screen and (max-width: 900px) {
          text-align: center;
          font-size: 1.4rem;
        }
      }

      > p {
        margin-bottom: 20px;
        font-size: 1em;
        width: 90%;
      }
    }

    > .content-right {
      width: 90%;
      display: flex;
      flex-direction: column;
      align-items: center;

      > p {
        font-size: 0.8rem;
        font-weight: 300;
        text-align: justify;
        @media screen and (max-width: 900px) {
          text-align: center;
        }
        > span {
          font-size: 0.9rem;
          font-weight: 500;
          font-style: italic;
        }
      }
    }
  }

  > .item {
    img {
      width: 100%;
    }
  }

  > .card {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    > .item {
      border: solid 1px #f3f3f3;
      border-radius: 8px;
      padding: 50px;
      width: 20%;
      min-height: 250px;
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 15px;
      margin: 10px;
      justify-content: center;
      text-align: center;
      @media screen and (max-width: 900px) {
        width: 80%;
        min-height: 120px;
        padding: 0px;
      }

      p {
        font-size: medium;
      }
    }
  }
`;

export const NossosNumerosContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 25px;
  width: 100%;
  background-color: #732c92;
  padding: 50px;
  min-height: 80vh;
  @media screen and (max-width: 900px) {
    padding: 10px;
  }

  > .header {
    display: flex;

    align-items: center;
    justify-content: space-around;
    @media screen and (max-width: 900px) {
      flex-direction: column;
      margin-top: 40px;
    }

    > .vantagens {
      > div {
        display: flex;
        flex-direction: column;
      }
      > p {
        font-style: italic;
        color: #fff;
        font-size: 0.8rem;
        @media screen and (max-width: 900px) {
          text-align: center;
          font-size: 0.6rem;
        }
      }
    }

    h3 {
      color: white;
      margin-right: 20px;
      font-size: 1.2rem;
      font-weight: 500;
      @media screen and (max-width: 900px) {
        text-align: center;
        font-size: 1rem;
        font-weight: 500;
        margin-right: 0px;
        margin-bottom: 10px;
      }
    }

    > p {
      color: white;
      font-size: 0.7rem;
      font-weight: 200;
      width: 50%;
      @media screen and (max-width: 900px) {
        width: 100%;
        text-align: center;
        margin-top: 10px;
      }

      > b {
        color: #fff;
        font-weight: 400;
      }
    }
  }
  > .card {
    margin: 60px 0 60px 0;
    align-items: center;
    justify-content: center;
    display: flex;
    gap: 54px;
    flex-wrap: wrap;
    @media screen and (max-width: 900px) {
      flex-direction: column;
      gap: 0px;
      margin-bottom: 30px;
    }

    .item {
      display: flex;
      gap: 25px;
      width: 39%;
      font-size: 1.7em;
      line-height: 30px;
      text-align: center;
      margin-top: 20px;
      padding: 30px 5px;
      margin-right: 30px;
      border-radius: 10px;
      min-height: 116px;
      @media screen and (max-width: 900px) {
        flex-direction: column;
        width: 90%;
        align-items: center;
        padding: 0px;
      }

      > div {
        display: flex;
        flex-direction: column;
        text-align: start;
        @media screen and (max-width: 900px) {
          text-align: center;
        }
        > p {
          font-size: 0.7rem;
          font-weight: 200;

          color: #fff;
          line-height: initial;
          @media screen and (max-width: 900px) {
            margin-top: 0px;
          }
        }

        > h2 {
          color: #fff;
          font-weight: 400;
          font-size: 0.9rem;

          @media screen and (max-width: 900px) {
            font-weight: 300;
          }
        }
      }

      img {
        width: 75px;
        @media screen and (max-width: 900px) {
          width: 60px;
        }
      }
    }
  }
`;

export const ListaSolucoes = styled.ul`
  padding: 20px;
  width: 40%;
  @media screen and (max-width: 1100px) {
    width: 90%;
  }
  @media screen and (max-width: 613px) {
    width: 100%;
  }

  > p {
    font-size: 0.8rem;
  }
`;

export const SolucoesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 80px;
  @media screen and (max-width: 900px) {
    flex-direction: column;
  }

  img {
    width: 40%;
  }

  > .content-container {
    height: 90%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 1500px;

    > div {
      padding: 50px;
      width: 40%;
      @media screen and (max-width: 1100px) {
        display: none;
      }

      > h1 {
        color: #732c92;
        font-size: 2rem;
        padding: 30px;
      }

      > p {
        font-size: 0.8rem;
      }
      > img {
        width: 100%;
        margin-bottom: 30px;
      }
      > button {
        border: none;
        width: 50%;
        padding: 10px;
        border-radius: 10px;
        background-color: #00aeef;
        color: white;
        margin-top: 20px;
        font-size: 0.9rem;

        @media screen and (max-width: 850px) {
          width: 90%;
        }
      }
    }

    > .content {
      width: 100%;
      > .header {
        display: flex;
        justify-content: space-evenly;
        width: 100%;
        border-bottom: 1px solid #0f0a15;

        > div {
          cursor: pointer;
        }

        > :first-child svg {
          width: 205px;
        }

        > :last-child svg {
          width: 358px;
        }
      }

      > .body {
        padding: 60px 50px;
      }

      > .body > div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;

        min-height: 400px;
        background: #5d0180;
        padding: 30px 50px;
      }

      > .body > div .imanage-solucao-image {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto 0;
        left: 45%;
        width: 60%;
      }

      > .body > div .digital-experience-solucao-image {
        position: absolute;
        height: 130%;
        top: -50px;
        left: 60%;
      }

      > .body > div > .imanage-title {
        color: #cf93ea;
        width: 320px;
      }

      > .body > div > .digital-experience-title {
        color: #cf93ea;
        width: 500px;
      }

      > .body > div > .imanage-description {
        width: 50%;
        font-size: 0.9em;
      }

      > .body > div > .digital-experience-description {
        width: 40%;
        min-width: 260px;
        font-size: 0.9em;
      }

      > .body > div > p > strong {
        font-size: 1em;
      }

      > .body > div > a,
      > .body > div > a:active,
      > .body > div > a:visited,
      > .body > div > a:link {
        color: #cf93ea;
        text-decoration: underline;
        font-size: 0.9em;
        transition: transform 0.2s linear;
        transform-origin: left;
      }

      > .body > div > a:hover {
        transform: scale(1.1);
        color: #fff;
      }
    }
  }

  @media screen and (max-width: 1280px) {
    > .content-container > .content > .body > div .imanage-solucao-image {
    }

    > .content-container
      > .content
      > .body
      > div
      .digital-experience-solucao-image {
      left: 50%;
    }

    > .content-container > .content > .body > div > .digital-experience-title {
      width: 300px;
    }
  }

  @media screen and (max-width: 1050px) {
    > .content-container {
      > h3 {
        font-size: 1.8em;
      }

      > .content {
        > .header {
          > :first-child svg {
            width: 150px;
          }

          > :last-child svg {
            width: 258px;
          }
        }

        > .body > div > .digital-experience-title {
          font-size: 0.9em;
        }

        > .body > div > .digital-experience-description {
          font-size: 0.8em;
        }
      }
    }
  }

  @media screen and (max-width: 900px) {
    > .content-container {
      > h1 {
        text-align: center;
      }

      > h3 {
        font-size: 1.5em;
        text-align: center;
      }
      > .content {
        > .header {
          > :first-child svg {
            width: 110px;
          }

          > :last-child svg {
            width: 200px;
          }
        }

        > .body > div > .imanage-title {
          width: 100%;
        }

        > .body > div > .imanage-description {
          width: 100%;
        }

        > .body > div > .digital-experience-title {
          width: 100%;
        }

        > .body > div > .digital-experience-description {
          width: 100%;
        }

        > .body > div .imanage-solucao-image {
          display: none;
        }

        > .body > div .digital-experience-solucao-image {
          display: none;
        }
      }
    }
  }

  @media screen and (max-width: 670px) {
    > .content-container {
      > h3 {
        font-size: 1.3em;
      }

      > .content {
        > .header {
          > :first-child svg {
            width: 90px;
          }

          > :last-child svg {
            width: 160px;
          }
        }

        > .body {
          padding: 30px 0;
        }
      }
    }
  }

  @media screen and (max-width: 440px) {
    > .content-container {
      width: 90%;

      > h3 {
        font-size: 1em;
      }

      > .content {
        > .header {
          > :first-child img {
            width: 80px;
          }

          > :last-child img {
            width: 140px;
          }
        }

        > .body {
          padding: 20px 0;
        }
      }
    }
  }
`;

export const TradeMarketingContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  background-color: #732c92;

  > div {
    height: 100%;
    > img {
      height: 100%;
    }
  }

  > .container {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10%;
    @media screen and (max-width: 1140px) {
      flex-direction: column;
    }

    .image-right {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: auto;
      width: 80%;
      max-height: 500px;
      @media screen and (max-width: 1140px) {
        height: 800px;
      }
      @media screen and (max-width: 1136px) {
        width: 100%;
      }

      ::-webkit-scrollbar {
        width: 0px;
        background-color: transparent;
      }

      ::-webkit-scrollbar-track {
        background-color: transparent;
      }

      ::-webkit-scrollbar-thumb {
        background-color: transparent;
        border-radius: 6px;
      }

      ::-webkit-scrollbar-thumb:hover {
        background-color: #555;
      }
      > div {
        display: flex;
        position: relative;

        > img {
          padding: 15px;
          width: 60%;
          @media screen and (max-width: 1140px) {
            width: 35%;
          }
        }

        ::before {
          position: absolute;
          content: "";
          left: 0;
          top: 0;
          z-index: 99;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: auto;
          width: 150px;

          background-image: linear-gradient(90deg, #732c92, transparent);
        }

        ::after {
          position: absolute;
          content: "";
          right: 0;
          top: 0;
          z-index: 99;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: auto;
          width: 150px;

          background-image: linear-gradient(-90deg, #732c92, transparent);
        }
      }
    }

    > .image-container {
      display: flex;
      height: 50%;

      > :nth-child(1) {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        width: 50%;
        height: 100%;
        > img {
          height: 100%;
        }
      }

      > :nth-child(2) {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        width: 50%;
        height: 100%;
        > img {
          height: 100%;
        }
      }
    }

    > .description {
      display: flex;
      align-items: center;
      height: 50%;
      @media screen and (max-width: 1140px) {
        height: 100%;
        justify-content: center;
      }

      > p {
        width: 68%;
        color: white;
        font-size: 1rem;
        font-weight: 200;
        @media screen and (max-width: 1136px) {
          width: 100%;
        }

        > b {
          font-size: 1em;
          color: white;
        }
      }
    }
  }

  @media screen and (orientation: portrait) {
    height: initial;
    > .image-left {
      display: none;
    }

    > .container {
      width: 100%;
      > .image-container {
        height: 250px;
        img {
        }
      }
    }
  }

  @media screen and (orientation: portrait) and (min-width: 480px) {
    > .container {
      > .image-container {
        height: 300px;
      }
    }
  }

  @media screen and (orientation: portrait) and (min-width: 580px) {
    > .container {
      > .image-container {
        height: 400px;
      }
    }
  }

  @media screen and (max-width: 1170px) {
    > .container > .description > p {
      font-size: 0.8em;
    }
  }

  @media screen and (max-width: 990px) {
    > .container > .description > p {
      font-size: 1rem;
    }
  }

  @media screen and (max-width: 860px) {
    > .container > .description > p {
      font-size: 1.1em;
    }
  }

  @media screen and (max-width: 560px), (max-height: 650px) {
    > .container > .description > p {
      font-size: 0.9em;
    }
  }

  @media screen and (max-width: 430px), (max-height: 500px) {
    > .container > .description > p {
      font-size: 0.8em;
    }
  }

  @media screen and (max-width: 1132px) {
    height: 520px;
    > .image-left {
      height: 100%;
    }
  }

  @media screen and (min-width: 1110px) {
    height: 500px;
    > .image-left {
      height: 100%;
    }
  }

  @media screen and (min-width: 1495px) {
    height: 520px;
    > .image-left {
      height: 100%;
    }
  }

  @media screen and (min-width: 1700px) {
    height: 500px;
    > .image-left {
      height: 100%;
    }
  }

  @media screen and (min-width: 2000px) {
    height: 800px;
    > .image-left {
      height: 100%;
    }
  }
`;

export const ClientesContainer = styled.div`
  /* width: 90%; */
  /* margin: 50px; */
  min-height: 55vh;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 920px) {
    flex-direction: column;
    margin: 0px;
    min-height: 50vh;
  }
  

  > img {
    width: 100%;
    @media screen and (max-width: 1016px) {
      width: 80%;
    }
  }

  > .left {
    width: 40%;
    @media screen and (max-width: 1016px) {
      width: 80%;
    }

    img {
      width: 90%;
    }

    > h1 {
      font-size: 1.9rem;
      color: #732c92;
      @media screen and (max-width: 1236px) {
        font-size: 1.5rem;
      }
    }
    > p {
      text-align: center;
      width: 350px;
      font-size: 1.4em;
      font-weight: 300;
      > b {
        font-size: 1em;
        color: #a43dd3;
      }
    }
  }

  > .right {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    /* @media screen and (max-width: 1016px) {
      width: 50%;
    } */

    p {
      font-size: 0.9rem;
      font-weight: 300;
      width: 100%;
      gap:30px;
      display: flex;
      @media screen and (max-width: 1016px) {
        flex-direction: column;
        gap:30px;
        text-align: center;
      }

      b {
        font-size: 0.9rem;
        font-weight: 600;
        /* @media screen and (max-width: 1016px) {
          font-weight: 600;
        } */
      }
    }
    > button {
      background-color: transparent;
      color: #732c92;
      /* padding: 15px; */
      /* border-radius: 10px; */
      margin-top: 30px;
      border: none;
      font-size: 0.8rem;
      border-bottom: 1px solid ;
      


      /* @media screen and (max-width: 1016px) {
        width: 100%;
        font-size: 0.8rem;
        margin-bottom: 30px
      } */
    }
  }
`;
