
import ReactGA from 'react-ga';
import {useEffect} from 'react';
import TailorDev from "../../components/TailorDev";
import { TailorDevPageContainer } from "./style";
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import WhatsappComponent from "../../components/WhatsappComponent";

const TailorDevPage = () => {
    useEffect(()=>{
        ReactGA.pageview(window.location.pathname);
    },[])

    return (
        <TailorDevPageContainer>
            <Header />
            <TailorDev />
            <Footer />
            <WhatsappComponent />
        </TailorDevPageContainer>
    )
};

export default TailorDevPage;