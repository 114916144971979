import {
  HomeContainer,
  QuemSomosContainer,
  SaibaMaisContainer,
  SolucoesContainer,
  TradeMarketingContainer,
  BoxHome,
  Description2,
  BtSaibaMais,
  NossosNumerosContainer,
  ListaSolucoes,
  EntreEmContatoContainer,
  Trade,
  Links,
} from "./styles";
import imgDXP from "../../assets/digitalExperienceTela.png";
import { Link, useNavigate } from "react-router-dom";
import carrossel01 from "../../assets/carrossel01.png";
import carrossel02 from "../../assets/carrossel02.png";
import carrossel03 from "../../assets/carrossel03.png";
import carrossel04 from "../../assets/carrossel04.png";
import carrossel05 from "../../assets/carrossel05.png";
import carrossel06 from "../../assets/carrossel06.png";
import carrossel07 from "../../assets/carrossel07.png";
import foguete from "../../assets/foguete.png";
import foguete1 from "../../assets/foguete (1).png";
import seta from "../../assets/seta.png";
import NossosClientes from "../NossosClientes";
import FormEmail from "../FormEmail";

const Home = () => {
  const navigate = useNavigate();

  function scrollIntoView(elementId) {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }

  return (
    <HomeContainer>
      <QuemSomosContainer id="quem-somos">
        <div className="description">
          <BoxHome>
            <h1>Muito prazer, somos a iFollow</h1>
            <Description2>
              <p>
                Nós somos uma empresa de <br />
                <b>tecnologia</b>, que desenvolve soluções de software e
                hardware para trazer mais inovação ao Trade Marketing da sua
                empresa!
                <img src={foguete} alt="foguete" />
              </p>
            </Description2>
          </BoxHome>
          <div className="div-saiba-mais">
            <p>
              Através da tecnologia possibilitamos a inovação, melhoria na
              gestão de ativos e campanhas de Trade Marketing.
            </p>
            <p>
            Desenvolvemos soluções com agilidade, 
            construindo relações de longo prazo.
            </p>
            <BtSaibaMais
              onClick={() => {
                scrollIntoView("saiba-mais");
                return false;
              }}
            >
              saiba mais{" >"}
            </BtSaibaMais>
          </div>
        </div>
      </QuemSomosContainer>
      <Trade>
        <SaibaMaisContainer id="saiba-mais">
          <div className="content-left">
            <h1>
              <span>Tecnologia</span> e <span>Gestão Inteligente</span>
              <br></br>em Trade Marketing
            </h1>
          </div>
          <div className="content-right">
            <div className="header">
              <p>
                Temos orgulho em dizer que somos uma das empresas mais
                inovadoras na prestação de{" "}
                <b>serviços de tecnologia de Trade Marketing</b> no país.
              </p>
              <p>
                Trabalhamos todos os dias para trazer{" "}
                <b>soluções inovadoras e eficientes</b> para que as empresas
                aumentem suas vendas, otimizando a atuação dos seus canais de
                distribuição.
              </p>
              <p>
                Tudo isso porque{" "}
                <b>queremos que nossos clientes se destaquem</b> e usem nossos
                serviços para voar cada vez mais alto.
              </p>
              <Link to="/quem-somos">
                Conheça nossa empresa <img src={seta} alt="seta" />
              </Link>
            </div>
          </div>
        </SaibaMaisContainer>

        <NossosNumerosContainer>
          <h3>
            <b>Como inovamos</b>
            <span>Nossos números</span>{" "}
            <i>
              que não param de subir! <img src={foguete1} alt="foguete" />
            </i>
          </h3>
          <div className="card">
            <div className="item">
              <h2>+1.000</h2>
              <p>ativos rastreáveis em campo</p>
            </div>
            <div className="item">
              <h2>+7.000</h2>
              <p>pontos de vendas atendidos</p>
            </div>
            <div className="item">
              <h2>+3.000</h2>
              <p>telas de digital signage implementadas</p>
            </div>
            <div className="item">
              <h2>+1.200</h2>
              <p>
                campanhas <br></br> realizadas
              </p>
            </div>
            <div className="item">
              <h2>+18.500</h2>
              <p>usuários em nossas plataformas</p>
            </div>
          </div>
        </NossosNumerosContainer>
      </Trade>

      <SolucoesContainer id="solucoes">
        <div className="content-container">
          <ListaSolucoes>
            <h1>Conheça nossas soluções</h1>
            <p>
              Desenvolvemos quatro frentes de serviços que aplicam a tecnologia
              em momentos diferentes do Trade Marketing
            </p>
            <li className="dxpLi">
              <p>
                <span>01</span>Digital Experience
              </p>

              <div className="dxp-left">
                <img src={imgDXP} alt="tela digital experience" />
                <p>
                  Quer fazer seu produto aparecer mais no PDV e aumentar suas
                  vendas?<br></br>
                  Já diziam por aí: quem não é visto, não é lembrado. Mas, muito
                  mas importante do que ser visto, é conseguir mostrar aquilo
                  que você quer, na hora que você quiser. E é justamente isso
                  que nós oferecemos com Digital Experience.
                </p>
                <button onClick={() => navigate("/digital-experience")}>
                  Saiba mais {" >"}
                </button>
              </div>
            </li>
            <li
              className="dxp-Desk"
              onClick={() => navigate("/digital-experience")}
            >
              <span>01</span>Digital Experience
            </li>
            <li onClick={() => navigate("/imanage")}>
              <span>02</span>iManage
            </li>
            <li onClick={() => navigate("/analytics")}>
              <span>03</span>iFollow Analytics
            </li>
            <li onClick={() => navigate("/tailor-dev")}>
              <span>04</span>Tailor Dev
            </li>
          </ListaSolucoes>
          <div className="dxp-right">
            <img src={imgDXP} alt="tela digital experience" />

            <h3>Digital Experience</h3>
            <p>
              Quer fazer seu produto aparecer mais no PDV e aumentar suas
              vendas?<br></br>
              Já diziam por aí: quem não é visto, não é lembrado. Mas, muito mais
              importante do que ser visto, é conseguir mostrar aquilo que você
              quer, na hora que você quiser. E é justamente isso que nós
              oferecemos com Digital Experience.
            </p>
            <button onClick={() => navigate("/digital-experience")}>
              Saiba mais {" >"}
            </button>
          </div>
        </div>
      </SolucoesContainer>

      <TradeMarketingContainer>
        <div className="container">
          <div className="description">
            <p>
              Estamos <b>reinventando o Trade Marketing</b> com o processo de
              inovação contínua, usando a tecnologia certa, para o objetivo
              certo!
            </p>
          </div>
          <div className="box">
            <div className="image-right">
              <div>
                <img src={carrossel01} alt="carrossel" id="carrossel01" />
                <img src={carrossel02} alt="carrossel" id="carrossel02" />
                <img src={carrossel03} alt="carrossel" id="carrossel03" />
                <img src={carrossel04} alt="carrossel" id="carrossel04" />
                <img src={carrossel05} alt="carrossel" id="carrossel05" />
                <img src={carrossel06} alt="carrossel" id="carrossel06" />
                <img src={carrossel07} alt="carrossel" id="carrossel07" />
              </div>
            </div>
          </div>
          <Links>
            <a className="link" href="#carrossel01">
              <button></button>
            </a>
            <a className="link" href="#carrossel02">
              <button></button>
            </a>
            <a className="link" href="#carrossel03">
              <button></button>
            </a>
            <a className="link" href="#carrossel04">
              <button></button>
            </a>
            <a className="link" href="#carrossel05">
              <button></button>
            </a>
            <a className="link" href="#carrossel06">
              <button></button>
            </a>
            <a className="link" href="#carrossel07">
              <button></button>
            </a>
          </Links>
        </div>
      </TradeMarketingContainer>

      <NossosClientes />

      <EntreEmContatoContainer id="clientes">
        <div className="content">
          <img src={imgDXP} alt="digital-experience" />
          <FormEmail />
        </div>
      </EntreEmContatoContainer>
    </HomeContainer>
  );
};

export default Home;
