import styled from "styled-components";

export const HeaderContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 15vh;
  justify-content: space-between;
  padding: 0 6%;
  z-index: 10;
  background-color: transparent;
  display: flex;
  @media screen and (max-width: 900px) {
    margin-top: 10px;
  }

  > .menu-icon {
    display: none;
  }

  @media screen and (max-width: 1035px), (max-height: 500px) {
    height: 80px;
  }

  @media screen and (max-width: 1035px) {
    padding: 0 20px;
    align-items: center;

    > .menu-icon {
      display: flex;
      background-color: transparent;
      justify-content: center;
      align-items: center;

      border: none;

      svg {
        width: 40px;
        margin-top: 10px;
      }
    }
  }
`;
export const BtEntreEmContato = styled.button`
  background-color: #00aeef;
  color: white;
  font-size: 0.7rem;
  padding: 15px;
  border: none;
  border-radius: 10px;
  font-weight: 500;
  width: 239px;

  @media screen and (max-width: 1035px) {
    margin-top: 33px;
    width: 100%;
    font-weight: 400;
    margin-bottom: 35px;
    font-size: 0.8rem;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  height: 80%;

  > div {
    cursor: pointer;
    height: 100%;
    > div {
      height: 100%;
      svg {
        height: 100%;
        width: 115px;
      }
    }
  }
`;

export const HeaderNav = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 50px;

  a,
  a:visited,
  a:link,
  a:active {
    color: #fff;
    font-size: 0.7rem;
    text-decoration: none;
    font-weight: 300;
    white-space: nowrap;
  }

  > .solucoes-container {
    position: relative;
    z-index: 10;

    > button {
      background: none;
      border: none;
      font-size: 0.7rem;
      align-items: center;
      column-gap: 5px;
      font-weight: 300;
      color: white;
      display: flex;
    }
    img {
      width: 10px;
    }

    > .solucoes-nav {
      position: absolute;
      left: -65px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 200px;
      padding: 10px 0;
      background: #ffffff;
      box-shadow: 0px 3px 6px #00000047;
      border-radius: 18px;
      transition: visibility 0.2s, opacity 0.2s linear;
    }

    > .solucoes-nav > :nth-child(n) {
      border-bottom: 1px solid lightgray;
    }

    > .solucoes-nav > :last-child {
      border-bottom: none;
    }

    > .solucoes-nav > a,
    > .solucoes-nav > a:visited,
    > .solucoes-nav > a:link,
    > .solucoes-nav > a:active {
      display: flex;
      align-items: center;
      justify-content: center;
      color: gray;
      font-size: 0.7em;
      font-weight: 300;
      width: 100%;
      height: 45px;
      :hover {
        color: #732c92;
        font-weight: 600;
      }
    }
  }

  .invisible {
    opacity: 0;
    visibility: hidden;
  }

  @media screen and (min-width: 1035px) {
    .mobileNav,
    .mobileNavBt {
      display: none;
    }
  }

  @media screen and (max-width: 1035px) {
    display: ${({ menuOpened }) => (menuOpened ? "none" : "flex")};
    position: absolute;
    top: 0px;
    left: 0;
    right: 0;
    width: 100%;
    flex-direction: column;
    min-width: initial;
    padding: 25px;
    background-color: #ffffff;
    text-align: end;
    align-items: end;
    gap: 20px;

    a,
    a:visited,
    a:link,
    a:active {
      color: black;
      font-size: 0.8rem;
      font-weight: 300;
      border-bottom: 1px solid #e5e5e5;
      width: 335px;
      text-align: end;
    }
    .mobileNavBt {
      border: none;
      background-color: transparent;
      font-size: 1.5rem;
      color: #732c92;
      font-weight: 300;
    }

    > .solucoes-container {
      display: none;
    }
  }

  @media screen and (min-width: 550px) {
    left: auto;
    width: 70%;
  }
`;
