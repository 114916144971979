import styled from "styled-components";
import homeBg from "../../assets/TaylorDev_Fundo.png";

export const TailorDevContainer = styled.div``;

export const ApresentacaoContainer = styled.div`
  background-image: url(${homeBg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 95vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  @media screen and (max-width: 900px) {
    justify-content: center;
    align-items: center;
    min-height: 100vh;
  }

  > .content {
    margin-left: 5%;
    width: 37%;
    padding-left: 5%;
    align-items: start;
    flex-direction: column;
    display: flex;
    justify-content: center;
    @media screen and (max-width: 900px) {
      width: 85%;
      align-items: center;
      text-align: center;
      padding-left: 0px;
    }

    h1 {
      font-size: 1.8rem;
      font-weight: 500;
      padding-bottom: 10px;
      color: white;
    }
    p {
      color: white;
      font-size: 0.8rem;
      font-weight: lighter;

      > span {
        color: white;
        font-weight: 600;
        font-size: 0.8rem;
      }
    }

    > button {
      margin: 40px 0 0 0;
      border: none;
      background-color: #00aeef;
      padding: 20px;
      width: 75%;
      border-radius: 15px;
      font-size: 0.7rem;
      color: white;
    }
  }
`;

export const ImagesContainer = styled.div`
  width: 100%;
  margin-top: 100px;

  > .body {
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
    padding-bottom: 50px;
    @media screen and (max-width: 900px) {
      flex-direction: column;
    }

    > .content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: start;
      width: 35%;
      @media screen and (max-width: 900px) {
        width: 100%;
        align-items: center;
        justify-content: center;
        text-align: center;
      }

      > img {
        width: 80%;
        @media screen and (min-width: 900px) {
          display: none;
        }
      }

      li {
        padding: 10px;
        list-style: none;

        p {
          font-size: 0.8em;
        }
      }
    }

    > .right {
      display: flex;
      width: 50%;
      @media screen and (max-width: 900px) {
        width: 100%;
        align-items: center;
        justify-content: center;
      }
      img {
        width: 100%;
        @media screen and (max-width: 900px) {
          display: none;
        }
      }
    }

    > .left {
      display: flex;
      width: 50%;
      @media screen and (max-width: 900px) {
        width: 100%;
        align-items: center;
        justify-content: center;
      }
      img {
        width: 85%;
        @media screen and (max-width: 900px) {
          display: none;
        }
      }
    }
  }
  > .body > .content > h2 {
    color: #732c92;
    font-size: 1.5rem;
    font-weight: 600;
    width: 80%;
    margin-bottom: 15px;
  }

  > .body > .content > p {
    color: #252525;
    font-size: 0.7em;
    width: 80%;

    span {
      font-size: 0.7rem;
      font-weight: 800;
    }
  }
`;

export const SaibaMaisContainer = styled.div`
  width: 100%;
  min-height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
  border-bottom: solid 1px lightgray;
  @media screen and (max-width: 900px) {
    flex-direction: column;
    gap: 0px;
  }

  > .content-left {
    width: 30%;
    @media screen and (max-width: 900px) {
      width: 90%;
      text-align: center;
    }

    > h1 {
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 65px;
      margin-bottom: 20px;
      color: #732c92;
    }
    > img {
      width: 100%;
      @media screen and (min-width: 900px) {
        display: none;
      }
    }

    > p {
      margin-bottom: 20px;
      font-size: 0.7em;
      width: 90%;
    }
  }

  > .content-right {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  > .content-right > .header {
    display: flex;
    flex-direction: column;
    width: 100%;
    row-gap: 10px;
    margin-bottom: 80px;
    @media screen and (max-width: 900px) {
      text-align: center;
      justify-content: center;
      align-items: center;
    }
    p {
      margin-top: 80px;
      font-size: 0.8rem;
      @media screen and (max-width: 900px) {
        display: none;
      }
    }
    > a {
      color: #732c92;
      font-size: 0.8rem;
      font-weight: 600;
      margin-top: 20px;
      text-decoration: none;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #732c92;
      width: 265px;
    }
  }
`;

export const EntreEmContatoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;

 
  @media screen and (max-width: 1016px) {
    width: 90%;
    margin: 20px;
  }

  > .content {
    display: flex;
    gap: 70px;
    align-items: center;
    padding: 40px;
    @media screen and (max-width: 900px) {
      padding: 10px;
      }

    img {
      display: flex;
      width: 55%;

      @media screen and (max-width: 900px) {
        display: none;
      }
    }

    > .divInput {
      display: flex;
      flex-direction: column;
      width: 40%;
      margin-top:20px;
      margin-left: -80px;
      @media screen and (max-width: 1016px) {
          width: 100%;
          margin-left: 0px;
        }
      

      h3{
        font-size: 0.8rem;
      }

      p {
        margin-bottom: 10px;
        font-size: 0.8rem;
      }

      label {
        margin-top: 20px;
      }

      input,textarea {
        width: 100%;
        padding: 10px;
        border-radius: 8px;
        border: none;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
        font-size: 0.8rem;

        ::placeholder {
          font-size: 0.7rem;
        }
      }
      textarea,input:focus {
        outline: none;
      }
      button {
        margin: 20px 0 0 0;
        border: none;
        background-color: rgb(0, 174, 239);
        padding: 10px;
        width: 65%;
        border-radius: 15px;
        font-size: 0.8rem;
        color: white;
        @media screen and (max-width: 1016px) {
          width: 100%;
        }
      }
    }
  }
`;
