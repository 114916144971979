import Footer from "../../components/Footer";
import Header from "../../components/Header";
import ReactGA from 'react-ga';
import {useEffect} from 'react';
import QuemSomos from "../../components/QuemSomos";
import { QuemSomosPageContainer } from "./style";
import WhatsappComponent from "../../components/WhatsappComponent";

const QuemSomosPage = () => {
    useEffect(()=>{
        ReactGA.pageview(window.location.pathname);
    },[])

    return (
        <QuemSomosPageContainer>
            <Header />
            <QuemSomos />
            <Footer />
            <WhatsappComponent />
        </QuemSomosPageContainer>
    )
};

export default QuemSomosPage ;
