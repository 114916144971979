import styled from "styled-components";
import homeBg from "../../assets/fundo-quemSomos.png";

export const SobreNosContainer = styled.div`
  .div-saiba-mais {
    text-align: left;
    padding-top: 20px;

    > p {
      font-size: 0.7rem;
    }
  }
`;

export const QuemSomosContainer = styled.div`
  background-image: url(${homeBg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #080719;
  min-height: 550px;
  min-height: 850px;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    margin-top: 80px;
    margin-bottom: 80px;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;

    @media screen and (max-width: 900px) {
      flex-direction: column;
      margin-top: 138px;
    }

    > .div-left {
      width: 40%;
      display: flex;
      flex-direction: column;
      text-align: start;
      @media screen and (max-width: 900px) {
        width: 100%;
        text-align: center;
      }

      > p {
        color: white;
        font-size: 0.9rem;
        font-style: italic;
        font-weight: 300;
        margin-bottom: 20px;
      }

      h1 {
        text-align: start;
        font-size: 2rem;
        font-weight: 500;
        color: white;
        @media screen and (max-width: 900px) {
          text-align: center;
          font-size: 1.5rem;
        }
      }
    }

    > .div-right {
      margin-top: 67px;
      width: 40%;

      @media screen and (max-width: 900px) {
        width: 100%;
        margin-top: 10px;
      }

      > p {
        font-size: 0.7rem;
        color: #ffffff;
        font-weight: 200;
        text-align: start;
        @media screen and (max-width: 900px) {
          text-align: center;
          font-size: 0.9rem;
        }

        > span {
          color: #ffffff;
          font-weight: 500;
          font-size: 0.7rem;
        }
      }
    }
  }
`;

export const Links = styled.div`
  display: flex;
  gap: 15px;
  a {
    > button {
      width: 16px;
      padding: 8px;
      border: none;
      background-color: lightgray;
      margin-bottom: 50px;
      border-radius: 8px;

      &:focus {
        background-color: #732c92;
      }
      :hover {
        background-color: #732c92;
      }
    }

    @media screen and (min-width: 950px) {
      display: none;
    }
  }
`;

export const ComoFazemosContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  margin-left: 5%;

  > div {
    display: flex;
    gap: 40px;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 400px) {
      justify-content: start;
    }
  }

  @media screen and (max-width: 1030px) {
    width: 90%;

    > div {
      justify-content: start;
      overflow: auto;
      width: 100%;
      max-height: 500px;

      ::-webkit-scrollbar {
        width: 0px;
        background-color: transparent;
      }

      ::-webkit-scrollbar-track {
        background-color: transparent;
      }

      ::-webkit-scrollbar-thumb {
        background-color: transparent;
        border-radius: 6px;
      }

      ::-webkit-scrollbar-thumb:hover {
        background-color: #555;
      }
    }
  }

  .item {
    border: solid 1px lightgray;
    font-size: 1.7em;
    line-height: 30px;
    text-align: center;
    margin-top: 80px;
    margin-bottom: 50px;
    padding: 30px;
    border-radius: 10px;
    width: 18%;
    min-height: 240px;
    color: white;

    @media screen and (max-width: 1030px) {
      width: 400px;
      height: 290px;
    }
    @media screen and (max-width: 500px) {
      min-width: 45vw;
      height: 290px;
    }

    > img {
      width: 25%;
      margin-bottom: 10px;
    }
    > h3 {
      @media screen and (max-width: 900px) {
        font-size: 0.9rem;
      }
      color: #732c92;
    }

    > p {
      font-size: 0.5rem;
      margin-top: 15px;
      line-height: 1.5;
    }
  }
`;

export const SaibaMaisContainer = styled.div`
  background-size: cover;
  width: 100%;
  min-height: 35vh;
  margin-top: 80px;
  padding: 30px 0;
  display: flex;
  align-items: center;
  column-gap: 60px;
  flex-direction: column;

  > .content-left {
    width: 70%;

    > h1 {
      font-size: 2rem;
      font-weight: 600;
      color: #732c92;
    }
    p {
      font-size: 0.8rem;
      margin-top: 10px;
      text-align: center;

      @media screen and (min-width: 950px) {
        display: none;
      }
      b {
        font-size: 0.7rem;
        font-weight: 600;

        @media screen and (min-width: 950px) {
          display: none;
        }
      }
    }

    > .content-right {
      width: 45%;
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 20px;

      > p {
        font-size: 0.9rem;
        font-weight: 250;
        > span {
          font-size: 0.9rem;
          font-weight: 500;
          font-style: italic;
        }
      }
    }
  }

  .especialista {
    background-color: #00aeef;
    width: 25%;
    border: none;
    padding: 15px;
    font-size: medium;
    color: white;
    border-radius: 10px;

    @media screen and (max-width: 950px) {
      width: 85%;
      padding: 20px;
    }
  }

  @media screen and (max-width: 1540px) {
    > .content-left > h1 {
      font-size: 2em;
    }
  }

  @media screen and (max-width: 1420px) {
    > .content-left > h1 {
      font-size: 2em;
    }
  }

  @media screen and (max-width: 1310px) {
    > .content-left > h1 {
      font-size: 2.2em;
    }
  }

  @media screen and (max-width: 1200px) {
    > .content-left > h1 {
      font-size: 2em;
    }
  }

  @media screen and (max-width: 1100px) {
    column-gap: 50px;

    > .content-left > h1 {
      font-size: 1.8em;
      line-height: 50px;
    }

    > .content-left > p {
      font-size: 0.8em;
    }

    > .content-right > .header > div,
    > .content-right > .item {
      font-size: 1.5em;
    }

    > .content-right > .header > div > p,
    > .content-right > .item > p {
      line-height: 26px;
    }
  }

  @media screen and (max-width: 985px) {
    column-gap: 40px;

    > .content-left > h1 {
      font-size: 1.6em;
      min-width: 350px;
    }

    > .content-right > .header {
    }

    > .content-left > p {
      font-size: 0.7em;
    }

    > .content-right > .header > div {
      min-width: 140px;
    }

    > .content-right > .header > div,
    > .content-right > .item {
      font-size: 1.3em;
    }

    > .content-right > .header > div > p,
    > .content-right > .item > p {
      line-height: 23px;
    }
  }

  @media screen and (max-width: 790px) {
    column-gap: 30px;

    > .content-left > h1 {
      font-size: 1.3em;
      line-height: 35px;
      min-width: 320px;
    }

    > .content-left > p {
      font-size: 0.7em;
    }

    > .content-right > .header > div {
      min-width: 130px;
    }

    > .content-right > .header > div,
    > .content-right > .item {
      font-size: 1.2em;
    }

    > .content-right > .header > div > p,
    > .content-right > .item > p {
      line-height: 21px;
    }
  }

  @media screen and (max-width: 700px) {
    column-gap: 20px;

    > .content-left > h1 {
      font-size: 1.2em;
      line-height: 35px;
      min-width: 280px;
    }

    > .content-left > p {
      font-size: 0.6em;
    }

    > .content-right > .header > div {
      min-width: 110px;
    }

    > .content-right > .header > div,
    > .content-right > .item {
      font-size: 1em;
    }

    > .content-right > .header > div > p,
    > .content-right > .item > p {
      line-height: 17px;
    }
  }

  @media screen and (max-width: 870px) {
    flex-direction: column;

    > .content-left {
      width: 90%;
      text-align: center;
    }

    > .content-left > p {
      width: 100%;
    }

    > .content-left > span {
      font-size: 0.9rem;
    }

    > .content-right {
      width: 90%;
    }
  }
  > p {
    color: #732c92;
  }
`;

export const NossosNumerosContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 25px;
  width: 100%;
  background-color: #2b2b2b;
  padding: 50px;
  min-height: 120vh;
  margin-top: 80px;
  @media screen and (max-width: 950px) {
    min-height: 100vh;
  }

  > .header {
    display: flex;
    gap: 10px;
    align-items: center;
    width: 90%;

    @media screen and (max-width: 1030px) {
      margin-bottom: 50px;
      flex-direction: column;
    }

    h3 {
      color: white;
      font-size: 01rem;

      @media screen and (max-width: 950px) {
        font-size: 1.4rem;
        font-weight: 500;
        text-align: center;
      }
    }

    > div {
      display: flex;
      @media screen and (max-width: 950px) {
        flex-direction: column;
        text-align: center;
      }
      p {
        margin-left: 10px;
        color: white;
        font-size: 0.8rem;

        img {
          width: 16px;
          margin-left: 5px;
        }
      }
    }
  }

  @media screen and (max-width: 1030px) {
    > div {

      ::-webkit-scrollbar {
        width: 0px;
        background-color: transparent;
      }

      ::-webkit-scrollbar-track {
        background-color: transparent;
      }

      ::-webkit-scrollbar-thumb {
        background-color: transparent;
        border-radius: 6px;
      }

      ::-webkit-scrollbar-thumb:hover {
        background-color: #555;
      }
    }
  }
  > .card {
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    gap: 30px;
    flex-wrap: wrap;

    .item {
      width: 15%;
      font-size: 1.7em;
      line-height: 30px;
      text-align: center;
      margin-top: 20px;
      padding: 30px 24px;
      border: 1px solid #555;
      margin: 10px;
      border-radius: 10px;
      color: white;
      min-height: 220px;
      @media screen and (max-width: 950px) {
        min-width: 100%;
      }

      > img {
        width: 22%;
        margin-bottom: 10px;
      }

      > p {
        font-size: 0.6rem;
        margin-top: 15px;
        color: white;
      }

      > h2 {
        color: white;
      }
    }
  }
`;

export const ListaSolucoes = styled.ul`
  padding: 20px;
  width: 90%;

  > p {
    font-size: 0.7rem;
    @media screen and (max-width: 900px) {
      text-align: center;
    }

    > span {
      font-size: 0.7rem;
      font-weight: 600;
    }
  }
`;

export const SolucoesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 80px;
  @media screen and (max-width: 613px) {
    margin: 2px;
  }

  > .content-container {
    height: 90%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 1500px;
    @media screen and (max-width: 900px) {
      flex-direction: column;
    }

    > div {
      width: 35%;
      @media screen and (max-width: 900px) {
        align-items: center;
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: 50px;
      }

      > h1 {
        color: #732c92;
        font-size: 2rem;
        padding: 30px;
        @media screen and (max-width: 900px) {
          font-size: 1.7rem;
          text-align: center;
        }
      }

      > p {
        font-size: 0.8rem;
      }
      > img {
        width: 100%;
        margin-bottom: 30px;
      }
      > button {
        border: none;
        width: 50%;
        padding: 10px;
        border-radius: 10px;
        background-color: #00aeef;
        color: white;
        margin-top: 20px;
        font-size: 0.9rem;

        @media screen and (max-width: 850px) {
          width: 90%;
        }
      }
    }

    > .content {
      width: 100%;
      > .header {
        display: flex;
        justify-content: space-evenly;
        width: 100%;
        border-bottom: 1px solid #0f0a15;

        > div {
          cursor: pointer;
        }

        > :first-child svg {
          width: 205px;
        }

        > :last-child svg {
          width: 358px;
        }
      }

      > .body {
        padding: 60px 50px;
      }

      > .body > div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;

        min-height: 400px;
        background: #5d0180;
        padding: 30px 50px;
      }

      > .body > div .imanage-solucao-image {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto 0;
        left: 45%;
        width: 60%;
      }

      > .body > div .digital-experience-solucao-image {
        position: absolute;
        height: 130%;
        top: -50px;
        left: 60%;
      }

      > .body > div > .imanage-title {
        color: #cf93ea;
        width: 320px;
      }

      > .body > div > .digital-experience-title {
        color: #cf93ea;
        width: 500px;
      }

      > .body > div > .imanage-description {
        width: 50%;
        font-size: 0.9em;
      }

      > .body > div > .digital-experience-description {
        width: 40%;
        min-width: 260px;
        font-size: 0.9em;
      }

      > .body > div > p > strong {
        font-size: 1em;
      }

      > .body > div > a,
      > .body > div > a:active,
      > .body > div > a:visited,
      > .body > div > a:link {
        color: #cf93ea;
        text-decoration: underline;
        font-size: 0.9em;
        transition: transform 0.2s linear;
        transform-origin: left;
      }

      > .body > div > a:hover {
        transform: scale(1.1);
        color: #fff;
      }
    }
  }

  .divImagens {
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 900px) {
      flex-wrap: wrap;
      margin-top: 50px;
    }

    img {
      padding: 5px;
      width: 16%;
      margin-top: 85px;
      @media screen and (max-width: 900px) {
        width: 50%;
        margin-top: 0px;
        padding: 10px;
      }
    }
  }

  @media screen and (max-width: 1280px) {
    > .content-container > .content > .body > div .imanage-solucao-image {
    }

    > .content-container
      > .content
      > .body
      > div
      .digital-experience-solucao-image {
      left: 50%;
    }

    > .content-container > .content > .body > div > .digital-experience-title {
      width: 300px;
    }
  }

  @media screen and (max-width: 1050px) {
    > .content-container {
      > h3 {
        font-size: 1.8em;
      }

      > .content {
        > .header {
          > :first-child svg {
            width: 150px;
          }

          > :last-child svg {
            width: 258px;
          }
        }

        > .body > div > .digital-experience-title {
          font-size: 0.9em;
        }

        > .body > div > .digital-experience-description {
          font-size: 0.8em;
        }
      }
    }
  }

  @media screen and (max-width: 900px) {
    > .content-container {
      > h1 {
        text-align: center;
      }

      > h3 {
        font-size: 1.5em;
        text-align: center;
      }
      > .content {
        > .header {
          > :first-child svg {
            width: 110px;
          }

          > :last-child svg {
            width: 200px;
          }
        }

        > .body > div > .imanage-title {
          width: 100%;
        }

        > .body > div > .imanage-description {
          width: 100%;
        }

        > .body > div > .digital-experience-title {
          width: 100%;
        }

        > .body > div > .digital-experience-description {
          width: 100%;
        }

        > .body > div .imanage-solucao-image {
          display: none;
        }

        > .body > div .digital-experience-solucao-image {
          display: none;
        }
      }
    }
  }

  @media screen and (max-width: 670px) {
    > .content-container {
      > h3 {
        font-size: 1.3em;
      }

      > .content {
        > .header {
          > :first-child svg {
            width: 90px;
          }

          > :last-child svg {
            width: 160px;
          }
        }

        > .body {
          padding: 30px 0;
        }
      }
    }
  }

  @media screen and (max-width: 440px) {
    > .content-container {
      width: 90%;

      > h3 {
        font-size: 1em;
      }

      > .content {
        > .header {
          > :first-child img {
            width: 80px;
          }

          > :last-child img {
            width: 140px;
          }
        }

        > .body {
          padding: 20px 0;
        }
      }
    }
  }
`;

export const TradeMarketingContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  background-color: #732c92;

  > div {
    height: 100%;
    > img {
      height: 100%;
    }
  }

  > .container {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10%;
    @media screen and (max-width: 1140px) {
      flex-direction: column;
    }

    .image-right {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: auto;
      width: 80%;
      max-height: 500px;
      @media screen and (max-width: 1140px) {
        height: 800px;
      }
      @media screen and (max-width: 1136px) {
        width: 100%;
      }

      ::-webkit-scrollbar {
        width: 0px;
        background-color: transparent;
      }

      ::-webkit-scrollbar-track {
        background-color: transparent;
      }

      ::-webkit-scrollbar-thumb {
        background-color: transparent;
        border-radius: 6px;
      }

      ::-webkit-scrollbar-thumb:hover {
        background-color: #555;
      }
      > div {
        display: flex;
        position: relative;

        > img {
          padding: 15px;
          width: 60%;
          @media screen and (max-width: 1140px) {
            width: 35%;
          }
        }

        ::before {
          position: absolute;
          content: "";
          left: 0;
          top: 0;
          z-index: 99;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: auto;
          width: 150px;

          background-image: linear-gradient(90deg, #732c92, transparent);
        }

        ::after {
          position: absolute;
          content: "";
          right: 0;
          top: 0;
          z-index: 99;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: auto;
          width: 150px;

          background-image: linear-gradient(-90deg, #732c92, transparent);
        }
      }
    }

    > .image-container {
      display: flex;
      height: 50%;

      > :nth-child(1) {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        width: 50%;
        height: 100%;
        > img {
          height: 100%;
        }
      }

      > :nth-child(2) {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        width: 50%;
        height: 100%;
        > img {
          height: 100%;
        }
      }
    }

    > .description {
      display: flex;
      align-items: center;
      height: 50%;
      @media screen and (max-width: 1140px) {
        height: 100%;
        justify-content: center;
      }

      > p {
        width: 68%;
        color: white;
        font-size: 1rem;
        font-weight: 200;
        @media screen and (max-width: 1136px) {
          width: 100%;
        }

        > b {
          font-size: 1em;
          color: white;
        }
      }
    }
  }
`;

export const ClientesContainer = styled.div`
  width: 100%;
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f7f7;

  > img {
    width: 100%;
    @media screen and (max-width: 1016px) {
      width: 80%;
    }
  }

  > .content > .divImg {
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  > .content {
    margin-top: 50px;
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 80px;
    width: 90%;
    justify-content: center;
    @media screen and (max-width: 1100px) {
      display: flex;
      flex-direction: column;
    }

    img {
      margin-bottom: 30px;
      width: 100%;
    }

    > h1 {
      font-size: 1.9rem;
      color: #732c92;
      @media screen and (max-width: 1236px) {
        font-size: 1.5rem;
      }
    }
    > p {
      text-align: center;
      width: 350px;
      font-size: 1.4em;
      font-weight: 300;
      > b {
        font-size: 1em;
        color: #a43dd3;
      }
    }

    > .body {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      gap: 40px;
      padding: 0 10px;

      > figure {
        width: 120px;
        > div {
          width: 100%;
          > img {
            width: 100%;
          }
        }
      }
    }
  }
`;

export const EntreEmContatoContainer = styled.div`
  width: 100%;

  display: flex;
  background-color: white;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 1016px) {
    width: 90%;
    margin: 20px;
  }

  > .content {
    display: flex;
    gap: 70px;
    align-items: center;
 

    img {
      display: flex;
      width: 55%;

      @media screen and (max-width: 1016px) {
        display: none;
      }
    }
  }
`;
