import styled from "styled-components";
import homeBg from "../../assets/home-bg.webp";

export const HomeContainer = styled.div`
  .div-saiba-mais {
    text-align: left;
    padding-top: 20px;

    > p {
      font-size: 0.7rem;
    }
  }
`;

export const QuemSomosContainer = styled.div`
  background-image: url(${homeBg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #080719;
  min-height: 860px;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  @media screen and (max-width: 650px) {
    min-height: 120vh;
  }

  > .description {
    width: 30%;
    display: flex;
    gap: 20px;
    align-items: flex-start;
    flex-direction: column;
    margin-left: 7%;
    @media screen and (max-width: 650px) {
      width: 100%;
      align-items: center;
      margin-left: 0px;
    }

    > h1 {
      display: flex;
      align-items: flex-end;
      font-weight: 500;
      font-size: 2.5rem;
      line-height: 80px;
      text-align: left;
      line-height: 1.3;

      > div {
        height: 130px;

        svg {
          width: 90px;
          height: 120px;
        }
      }
    }
  }

  > p {
    font-size: 1.5em;
    font-weight: 300;
    width: 70%;
    > b {
      font-size: 1em;
    }
  }

  .div-saiba-mais {
    width: 472px;
    p {
      font-size: 0.6rem;
      color: white;
      font-weight: 200;
      @media screen and (max-width: 650px) {
        font-size: 0.8em;
      }
    }
    @media screen and (max-width: 650px) {
      width: 95%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-bottom: 80px;
      p {
        text-align: center;
      }
    }
  }
`;

export const BoxHome = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  @media screen and (max-width: 650px) {
    flex-direction: column;
    margin-top: 100px;
  }
  > h1 {
    display: flex;
    align-items: flex-end;
    font-weight: 600;
    font-size: 2.3rem;
    line-height: 80px;
    text-align: left;
    line-height: 1.3;
    width: 225px;
    color: white;
    @media screen and (max-width: 650px) {
      width: 100%;
      align-items: center;
      display: contents;
    }
  }
`;

export const Description2 = styled.div`
  border-radius: 10px;
  width: 260px;
  text-align: start;
  background: #783c94;
  padding: 18px;
  margin-top: 50px;
  line-height: 1.6;

  @media screen and (max-width: 650px) {
    width: 100%;
    border-radius: 0px;
    text-align: center;
  }
  img {
    margin-left: 8px;
    width: 16px;
  }
  > p {
    font-size: 0.7em;
    color: white;
    font-weight: 200;
    @media screen and (max-width: 650px) {
      font-size: 0.9em;
    }

    > b {
      color: white;
      font-weight: 500;
      font-size: 0.7rem;
      @media screen and (max-width: 650px) {
        font-size: 0.9em;
      }
    }
  }
`;

export const Trade = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const NossosNumerosContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 85%;

  h3 {
    text-align: start;
    @media screen and (max-width: 900px) {
      flex-direction: column;
      display: flex;
      text-align: center;
    }
  }
  b {
    color: #732c92;
    font-weight: 800;
    font-size: 1.5rem;
    margin-right: 5px;
  }
  span {
    font-size: 0.8rem;
  }
  i {
    font-size: 0.8rem;
    font-weight: 300;
  }

  > .card {
    display: flex;
    gap: 30px;

    @media screen and (max-width: 900px) {
      display: flex;
      flex-direction: column;
    }
  }

  .item {
    font-size: 1.7em;
    line-height: 30px;
    text-align: center;
    margin-top: 20px;
    padding: 20px 5px;
    background-color: #732c92;
    border-radius: 10px;
    width: 32%;
    color: white;
    height: 120px;

    @media screen and (max-width: 900px) {
      width: 100%;
    }

    > h2 {
      font-size: 1.6rem;
      color: white;
      font-weight: 500;
    }

    > p {
      font-size: 0.6rem;
      margin-top: 15px;
      font-weight: 200;
      line-height: 18px;
      color: white;
    }
  }
`;

export const BtSaibaMais = styled.button`
  margin: 30px 0 0 0;
  border: none;
  background-color: rgb(0, 174, 239);
  padding: 20px;
  width: 60%;
  border-radius: 15px;
  font-size: 0.8rem;
  color: white;
  font-weight: 200;

  @media screen and (max-width: 650px) {
    width: 90%;
    padding: 25px;
    font-weight: 400;
    font-size: 0.9rem;
  }
`;

export const SaibaMaisContainer = styled.div`
  background-size: cover;
  width: 100%;
  padding: 60px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 60px;

  @media screen and (max-width: 900px) {
    flex-direction: column;
    width: 85%;
  }

  > .content-left {
    width: 37%;
    max-width: 650px;

    @media screen and (max-width: 900px) {
      width: 100%;
      text-align: center;
    }
    > h1 {
      font-size: 2.2rem;
      font-weight: 600;
      line-height: 65px;
      margin-bottom: 20px;
      color: #732c92;
      @media screen and (max-width: 900px) {
        font-size: 1.4rem;
      }
    }

    > h1 > span {
      font-size: 2.2rem;
      font-weight: 800;
      line-height: 65px;
      margin-bottom: 20px;
      color: #732c92;
      @media screen and (max-width: 900px) {
        font-size: 1.4rem;
      }
    }

    > p {
      font-size: 0.9em;
      width: 90%;
    }

    > p > b {
      font-size: 1em;
    }
  }

  > .content-right {
    width: 45%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 20px;

    @media screen and (max-width: 900px) {
      width: 100%;
      text-align: center;
    }
  }

  > .content-right > .header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    row-gap: 50px;
    column-gap: 20px;

    @media screen and (max-width: 900px) {
      justify-content: center;
    }
  }

  > .content-right > .header > div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 1.7em;
    font-weight: bold;
    line-height: 30px;
    width: 45%;
    min-width: 180px;
    padding: 20px 5px;
    text-align: center;
  }

  > .content-right > .header > div:hover {
    background-color: #a43dd3;
  }

  > .content-right > .header > p {
    font-size: 0.8rem;
    margin-bottom: -25px;
    color: #2b2b2b;

    b {
      font-size: 0.8rem;
    }
  }
  a {
    font-size: 0.8rem;
    color: #732c92;
    text-decoration: none;
    border-bottom: 1px solid #732c92;
    display: flex;
    align-items: center;
  }

  > .content-right > .item {
    font-size: 1.7em;
    font-weight: bold;
    line-height: 30px;
    text-align: center;
    margin-top: 20px;
    padding: 30px 5px;
  }

  > .content-right > .item:hover {
    background-color: #a43dd3;
  }

  > .content-right > .item > p {
    font-size: 0.55em;
    font-weight: 300;
  }
`;

export const ListaSolucoes = styled.ul`
  padding: 20px;
  width: 60%;

  @media screen and (max-width: 900px) {
    width: 100%;
  }

  > h1 {
    font-size: 2em;
    color: #a43dd3;
    font-weight: 600;
    margin-bottom: 30px;
    text-align: start;

    @media screen and (max-width: 900px) {
      text-align: center;
    }
  }
  p {
    font-size: 0.8rem;

    @media screen and (max-width: 900px) {
      text-align: center;
    }
  }

  .dxp-Desk {
    @media screen and (max-width: 900px) {
      display: none;
    }
  }

  .dxpLi {
    font-weight: 800;

    p {
      font-weight: 800;
      font-size: 1.4rem;
      margin-bottom: 40px;

      @media screen and (max-width: 650px) {
        font-weight: 800;
        font-size: 1.1rem;
        text-align: start;
        margin-bottom: 40px;
      }
    }
    .dxp-left {
      width: 100%;

      > p {
        font-size: 0.7rem;
        margin-top: 10px;
        font-weight: 400;
        text-align: center;
      }
      > img {
        width: 100%;
      }
      > button {
        border: none;
        width: 100%;
        padding: 15px;
        border-radius: 10px;
        background-color: #00aeef;
        color: white;
        margin-top: 20px;
        font-size: 0.8rem;
        font-weight: 200;
      }
    }
    display: none;
    @media screen and (max-width: 900px) {
      display: flex;
      flex-direction: column;
    }
  }

  > li {
    padding: 35px;
    padding-bottom: 50px;
    border-bottom: solid 1px lightgray;
    list-style: none;
    font-size: 1.3rem;
    cursor: pointer;

    @media screen and (max-width: 650px) {
      font-size: 1.1rem;
    }

    span {
      margin-right: 15px;
      font-weight: 500;
      color: #2b0047;
    }
  }
`;

export const SolucoesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 80px;

  @media screen and (max-width: 900px) {
    margin: 5px;
  }

  img {
    width: 30%;
  }

  > .content-container {
    height: 90%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 1500px;

    > div {
      width: 40%;

      @media screen and (max-width: 900px) {
        display: none;
      }

      > p {
        font-size: 0.7rem;
        margin-top: 10px;
      }
      > img {
        width: 100%;
      }
      > button {
        border: none;
        width: 50%;
        padding: 10px;
        border-radius: 10px;
        background-color: #00aeef;
        color: white;
        margin-top: 20px;
        font-size: 0.8rem;
        font-weight: 200;
      }
    }

    > .content {
      width: 100%;

      > .header {
        display: flex;
        justify-content: space-evenly;
        width: 100%;
        border-bottom: 1px solid #0f0a15;

        > div {
          cursor: pointer;
        }

        > :first-child svg {
          width: 205px;
        }

        > :last-child svg {
          width: 358px;
        }
      }

      > .body {
        padding: 60px 50px;
      }

      > .body > div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;

        min-height: 400px;
        background: #5d0180;
        padding: 30px 50px;
      }

      > .body > div .imanage-solucao-image {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto 0;
        left: 45%;
        width: 60%;
      }

      > .body > div .digital-experience-solucao-image {
        position: absolute;
        height: 130%;
        top: -50px;
        left: 60%;
      }

      > .body > div > .imanage-title {
        color: #cf93ea;
        width: 320px;
      }

      > .body > div > .digital-experience-title {
        color: #cf93ea;
        width: 500px;
      }

      > .body > div > .imanage-description {
        width: 50%;
        font-size: 0.9em;
      }

      > .body > div > .digital-experience-description {
        width: 40%;
        min-width: 260px;
        font-size: 0.9em;
      }

      > .body > div > p > strong {
        font-size: 1em;
      }

      > .body > div > a,
      > .body > div > a:active,
      > .body > div > a:visited,
      > .body > div > a:link {
        color: #cf93ea;
        text-decoration: underline;
        font-size: 0.9em;
        transition: transform 0.2s linear;
        transform-origin: left;
      }

      > .body > div > a:hover {
        transform: scale(1.1);
        color: #fff;
      }
    }
  }
`;

export const TradeMarketingContainer = styled.div`
  width: 100%;
  height: 60vh;
  display: flex;
  background-color: #732c92;
  @media screen and (max-width: 900px) {
    height: 80vh;
  }

  > div {
    height: 100%;
    > img {
      height: 100%;
    }
  }

  > .container {
    display: flex;
    position: relative;
    align-items: center;
    width: 100%;
    padding: 10px;
    min-height: 50vh;
    gap: 30px;
    justify-content: center;

    > .description {
      display: flex;
      align-items: center;
      width: 34%;

      > p {
        width: 100%;
        color: white;
        font-size: 1rem;
        font-weight: 300;

        > b {
          font-size: 1em;
          color: white;
        }
      }
    }

    ::before {
      position: absolute;
      content: "";
      right: 12%;
      top: 50px;
      z-index: 99;
      height: 75%;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 185px;
      background-image: linear-gradient(-90deg, #732c92, transparent);
    }

    .box {
      position: relative;
      width: 40%;
  
      overflow-x: scroll;

      ::-webkit-scrollbar {
        width: 0px;
        background-color: transparent;
        height: 8px;
      }

      ::-webkit-scrollbar-track {
        background-color: transparent;
      }

      ::-webkit-scrollbar-thumb {
        background-color: #2b0047;
        border-radius: 6px;
        @media screen and (max-width: 900px) {
          background-color: transparent;
        }
      }

      ::-webkit-scrollbar-thumb:hover {
        background-color: #00aeef;
      }
    }

    .image-right {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      max-height: 500px;

      > div {
        display: flex;
      

        > img {
          padding: 8px;
          width: 39%;
        }
      }
    }
  }
  @media screen and (max-width: 950px) {
    .container {
      flex-direction: column;

      .description {
        width: 80%;
        margin-bottom: 80px;

        p {
          text-align: center;
          margin-top: 20px;
        }
      }
      .box {
        width: 90%;
      }

      ::before {
        right: 49px;
        top: 160px;
        height: 70%;
      }
      .image-right {
        > div{
          height: 100%;
          > img {
          width: 70%;
          height: 100%;
        }
        } 
      }
    }
  }
  

  @media screen and (max-width: 920px) {
    .container {
      .description {
        margin-bottom: 0px;
      }
      ::before {
        right: 25px;
        top: 40%;
        height: 40%;
        width: 150px;
      }
    }
  }
`;

export const Links = styled.div`
  display: flex;
  gap: 15px;
  .link {
    text-decoration: none;
    border: none;
    width: 20px;
    > button {
      width: 10px;
      padding: 5px;
      border: none;
      background-color: lightgray;
      margin-bottom: 50px;
      border-radius: 5px;
      cursor: pointer;

      &:focus {
        background-color: #3498DB;
      }
      :hover {
        background-color: #3498DB;
      }
    }

    @media screen and (min-width: 950px) {
      display: none;
    }
  }
  `
export const EntreEmContatoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  margin: 10px;
  @media screen and (max-width: 900px) {
    margin: 0px;
  }

  > .content {
    display: flex;
    gap: 70px;
    align-items: center;

    img {
      display: flex;
      width: 55%;
    }
  }
  @media screen and (max-width: 900px) {
    .content {
      img {
        display: none;
      }

      .divInput {
        margin: 60px;
        input {
          width: 100%;
        }
        button {
          width: 100%;
        }
      }
    }
  }
  @media screen and (max-width: 500px) {
    .content {
      margin: 10px;
      img {
        display: none;
      }

      .divInput {
        margin: 20px;
        input {
          width: 100%;
        }
        button {
          width: 100%;
        }
      }
    }
  }
`;
