import {
  QuemSomosContainer,
  SaibaMaisContainer,
  SolucoesContainer,
  NossosNumerosContainer,
  ListaSolucoes,
  EntreEmContatoContainer,
  ComoFazemosContainer,
  SobreNosContainer,
  Links,
} from "./style";
import guaranaTela from "../../assets/Grupo de máscara 4.webp";
import hardware from "../../assets/fazemos-hardware.svg";
import software from "../../assets/fazemos-software.svg";
import Atendimento from "../../assets/fazemos-atendimento.svg";
import gestao from "../../assets/fazemos-gestão.svg";
import ativo from "../../assets/inovamos-ativo.svg";
import cidades from "../../assets/inovamos-cidade.svg";
import estados from "../../assets/inovamos-estado.svg";
import codigos from "../../assets/inovamos-linhas-codigo.svg";
import operacao from "../../assets/inovamos-operação.svg";
import paises from "../../assets/inovamos-paises.svg";
import pdv from "../../assets/inovamos-pdv.svg";
import rastreadores from "../../assets/inovamos-rastreadores.svg";
import suporte from "../../assets/inovamos-suporte.svg";
import usuario from "../../assets/inovamos-usuario.svg";
import NossosClientes from "../NossosClientes";
import foguete from "../../assets/foguete.png";
import inovacao1 from "../../assets/inovação01.png";
import inovacao2 from "../../assets/inovação02.png";
import inovacao3 from "../../assets/inovação03.png";
import inovacao4 from "../../assets/inovação04.png";
import inovacao5 from "../../assets/inovação05.png";
import inovacao6 from "../../assets/inovação06.png";
import "react-toastify/dist/ReactToastify.css";
import FormEmail from "../FormEmail";

const QuemSomos = () => {
  const handleWhatsAppClick = () => {
    const phoneNumber = "5511950853443";
    window.location.href = `https://api.whatsapp.com/send?phone=${phoneNumber}`;
  };

  return (
    <SobreNosContainer>
      <QuemSomosContainer id="quem-somos">
        <div>
          <div className="div-left">
            <p>Quem somos?</p>

            <h1>
              Entendemos sua necessidade para pensar e propor a solução que você
              precisa.
            </h1>
          </div>
          <div className="div-right">
            <p>
            Nascemos em 2020 com um propósito: usar a tecnologia a favor do Trade, trazendo assim soluções inovadoras e de gestão para o mercado de <span>Trade Marketing.</span> 
            </p>
            <br></br>
            <p>
            Concentramos nossa <span>expertise na criação e desenvolvimento de sistemas,</span> materiais e qualquer outro tipo de produto tecnológico que aprimore as atividades de <span>Trade Marketing</span> de nossos clientes, seja destacando seus produtos no PDV frente aos demais, automatizando a gestão de suas campanhas e ativos, ou ainda desenvolvendo soluções tecnológicas personalizados para suas demandas específicas.
            </p>
          </div>
        </div>
      </QuemSomosContainer>

      <SaibaMaisContainer id="saiba-mais">
        <div className="content-left">
          <h1>Como fazemos ?</h1>
          <p>
            A iFollow usa a tecnologia como matéria-prima para criar soluções
            que atendam muito mais do que as necessidades no Trade Marketing:
            <b> vamos além !</b>
          </p>
        </div>
        <ComoFazemosContainer>
          <div>
            <div className="item" id="hardware">
              <img src={hardware} alt="hardware" />
              <h3>Hardware</h3>
              <p>
                Inovamos sempre buscando novos resultados e qualidade para o
                Trade Marketing de nossos clientes.
              </p>
            </div>
            <div className="item" id="software">
              <img src={software} alt="software" />
              <h3>Software</h3>
              <p>
                Pensamos e criamos soluções personalizadas à base de tecnologia
                para as necessidades especificas de nossos clientes.
              </p>
            </div>
            <div className="item" id="Atendimento">
              <img src={Atendimento} alt="Atendimento" />
              <h3>Atendimento</h3>
              <p>
                Nosso atendimento é especializado e dedicado 24/7.
              </p>
            </div>
            <div className="item" id="gestao">
              <img src={gestao} alt="gestao" />
              <h3>Gestão</h3>
              <p>
                Acompanhamento simplificado de todas as etapas, sempre pensando
                no resultado final.
              </p>
            </div>
          </div>
        </ComoFazemosContainer>
        <Links>
          <a href="#hardware">
            <button></button>
          </a>
          <a href="#software">
            <button></button>
          </a>
          <a href="#Atendimento">
            <button></button>
          </a>
          <a href="#gestao">
            <button></button>
          </a>
        </Links>

        <button onClick={handleWhatsAppClick} className="especialista">
          Fale com um especialista {">"}
        </button>
      </SaibaMaisContainer>

      <NossosNumerosContainer>
        <div className="header">
          <h3>Como inovamos</h3>{" "}
          <div>
            {" "}
            <p> Nossos números </p>
            <p style={{ fontStyle: "italic" }}>
              {" "}
              que não param de subir! <img src={foguete} alt="foguete" />
            </p>
          </div>
        </div>
        <div className=" card">
          <div className="item">
            <img src={rastreadores} alt="rastreadores" />
            <h2>+1.000</h2>
            <p>ativos rastreados por loT</p>
          </div>
          <div className="item">
            <img src={pdv} alt="pdv" />
            <h2>+7.000</h2>
            <p>pontos de vendas atendidos</p>
          </div>
          <div className="item">
            <img src={ativo} alt="ativo" />
            <h2>+3.000</h2>
            <p>ativos digitais em campo</p>
          </div>
          <div className="item">
            <img src={suporte} alt="suporte" />
            <h2>24/7</h2>
            <p>suporte ao cliente</p>
          </div>
          <div className="item">
            <img src={usuario} alt="usuario" />
            <h2>+18.500</h2>
            <p>usuários em nossas plataformas</p>
          </div>
          <div className="item">
            <img src={codigos} alt="codigos" />
            <h2> +74.000</h2>
            <p>linhas de código de programação</p>
          </div>
          <div className="item">
            <img src={operacao} alt="coperacao" />
            <h2> +8.500</h2>
            <p>operações realizadas</p>
          </div>
          <div className="item">
            <img src={paises} alt="paises" />
            <h2>3</h2>
            <p>países em operação</p>
          </div>
          <div className="item">
            <img src={estados} alt="estados" />
            <h2>24</h2>
            <p>estados com digital experience</p>
          </div>
          <div className="item">
            <img src={cidades} alt="cidades" />
            <h2>+315</h2>
            <p>cidades do Brasil em operação</p>
          </div>
        </div>
      </NossosNumerosContainer>

      <SolucoesContainer id="solucoes">
        <div className="content-container">
          <div>
            <h1>Porque nos escolher ? </h1>
          </div>
          <ListaSolucoes>
            <p>
              <span>Inovação e agilidade estão em nosso DNA!</span>
              <br></br>Nós possibilitamos{" "}
              <span>que você vá mais longe através das nossas soluções </span> e
              nosso atendimento ágil. Temos muito conhecimento nas áreas de
              tecnologia e Trade Marketing, o que nos proporciona um diferencial
              muito grande no mercado. Quando juntamos as duas áreas, as
              soluções são mais integradas, ágeis, com maior custo-benefício e
              retorno para seu investimento.{" "}
              <span>
                Viabilizamos a tecnologia certa para o objetivo certo da sua
                campanha.
              </span>
            </p>
          </ListaSolucoes>
        </div>
        <div className="divImagens">
          <img src={inovacao1} alt="Nossas telas" />
          <img src={inovacao2} alt="Nossas telas" />
          <img src={inovacao3} alt="Nossas telas" />
          <img src={inovacao4} alt="Nossas telas" />
          <img src={inovacao5} alt="Nossas telas" />
          <img src={inovacao6} alt="Nossas telas" />
        </div>
      </SolucoesContainer>

      <NossosClientes />

      <EntreEmContatoContainer id="clientes">
        <div className="content">
          <img src={guaranaTela} alt="guarana"></img>
          <div>
            <FormEmail />
          </div>
        </div>
      </EntreEmContatoContainer>
    </SobreNosContainer>
  );
};

export default QuemSomos;
