import {
  BtEntreEmContato,
  HeaderContainer,
  HeaderNav,
  LogoContainer,
} from "./styles";
import { useState } from "react";
import { HashLink } from "react-router-hash-link";
import { useNavigate } from "react-router-dom";
import IFollowLogo from "../../assets/ifollow-logo.svg";
import TrianguloMenu from "../../assets/seta-direita-triangulo.png";
import MenuIcon from "../../assets/menu.svg";
import { ReactSVG } from "react-svg";
import  close from "../../assets/x (1).png";

const Header = () => {
  const [solucoesOpened, setSolucoesOpened] = useState(false);
  const [menuOpened, setMenuOpened] = useState(true);

  const handleWhatsAppClick = () => {
    const phoneNumber = "5511950853443";
    window.location.href = `https://api.whatsapp.com/send?phone=${phoneNumber}`;
  };

  const navigate = useNavigate();

  return (
    <HeaderContainer>
      <LogoContainer>
        <ReactSVG onClick={() => navigate("/")} src={IFollowLogo} />
      </LogoContainer>
      <HeaderNav menuOpened={menuOpened}>
        <button
          onClick={() => setMenuOpened(!menuOpened)}
          className="mobileNavBt"
        >
          <img src={close} alt="close"/>
        </button>
        <HashLink className="mobileNav" to="/">
          Home
        </HashLink>
        <HashLink to="/quem-somos">Sobre nós</HashLink>
        <HashLink className="mobileNav" to="/imanage">
          IManage
        </HashLink>
        <HashLink className="mobileNav" to="/digital-experience">
          Digital Experience
        </HashLink>
        <HashLink className="mobileNav" to="/tailor-dev">
          Tailor Dev
        </HashLink>
        <HashLink className="mobileNav" to="/analytics">
          iFollow Analytics
        </HashLink>
        <div
          className="solucoes-container"
          onMouseLeave={() => setSolucoesOpened(false)}
        >
          <button onMouseEnter={() => setSolucoesOpened(true)}>
            Soluções
            <img
              src={TrianguloMenu}
              alt="dropdown"
              style={{
                transform: solucoesOpened ? "matrix(-1, 0, 0, -1, 0, 0)" : "",
                transition: "transform 0.3s",
              }}
            />
          </button>
          <nav className={`solucoes-nav ${solucoesOpened ? "" : "invisible"}`}>
            <HashLink
              to="/digital-experience"
              state={{ open: "digitalExperience" }}
            >
              Digital Experience
            </HashLink>
            <HashLink to="/imanage" state={{ open: "imanage" }}>
              IManage
            </HashLink>
            <HashLink to="/analytics" state={{ open: "analytics" }}>
              iFollow Analytics
            </HashLink>
            <HashLink to="/tailor-dev" state={{ open: "tailorDev" }}>
              Tailor Dev
            </HashLink>
          </nav>
        </div>
        <HashLink to="/cases">Cases</HashLink>
        <HashLink to="#contato">Contato</HashLink>
        <BtEntreEmContato onClick={handleWhatsAppClick}>
          Fale com a gente
        </BtEntreEmContato>
      </HeaderNav>
      <button
        onClick={() => setMenuOpened(!menuOpened)}
        className="menu-icon"
        aria-label="Menu"
      >
        <ReactSVG src={MenuIcon} />
      </button>
    </HeaderContainer>
  );
};

export default Header;
