import {
  ApresentacaoContainer,
  CasesContainer,
  ComoFuncionaContainer,
  EntreEmContatoContainer,
  LiftLearnContainer,
  ProjetoAjinomoto,
  ProjetoCarnaval,
  ProjetoGoose,
  ProjetoMars,
  ProjetoStopper,
  ProjetoTableTend,
  Solucoes,
} from "./style";
import { HashLink } from "react-router-hash-link";
import elmaChipsForm from "../../assets/elma-chips-formulario.webp";
import mars1 from "../../assets/mars01.webp";
import digitalMars1 from "../../assets/m&m01.png";
import digitalMars2 from "../../assets/m&m02.png";
import digitalMars3 from "../../assets/m&m03.png";
import pepisco1 from "../../assets/pepsico01.png";
import pepisco2 from "../../assets/pepsico02.png";
import pepisco3 from "../../assets/pepsico03.png";
import stopper01 from "../../assets/stopper-faixa01.png";
import stopper02 from "../../assets/stopper-faixa02.png";
import stopper03 from "../../assets/stopper-faixa03.png";
import ajinomoto01 from "../../assets/ajinomoto01.png";
import ajinomoto02 from "../../assets/ajinomoto02.png";
import ajinomoto03 from "../../assets/ajinomoto03.png";
import tableTend01 from "../../assets/table-tent01.png";
import tableTend02 from "../../assets/table-tent02.png";
import tableTend03 from "../../assets/table-tent03.png";
import goose01 from "../../assets/goose-island01.png";
import goose02 from "../../assets/goose-island02.png";
import goose03 from "../../assets/goose-island03.png";
import seta from "../../assets/seta-baixo.png";
import flecha from "../../assets/seta.png";
import { Link } from "react-router-dom";
import FormEmail from "../FormEmail";
import videoLiftLearn from "../../assets/Design sem nome (6).mp4";
import videoMars from "../../assets/mars - video.mp4";

const Cases = () => {
  return (
    <CasesContainer>
      <ApresentacaoContainer>
        <div className="content">
          <h1>Cases</h1>
          <b>
            Quer trazer mais inovação e tecnologia para<br></br> suas ações no
            trade e aumentar suas vendas?
          </b>
          <p>
            A iFollow possui diversas soluções digitais e <br></br>tecnológicas
            para que seu produto <b>se destaque</b> no ponto de venda e se{" "}
            <b>diferencie frente à concorrência.</b> Além de aumentar as vendas,
            ajuda a <b>criar conexão do shopper </b>com as marcas, aumentando o
            poder da marca.
          </p>
          <HashLink to="#como-funciona">
            Conheça nossos cases <img src={seta} alt="seta" />
          </HashLink>
        </div>
      </ApresentacaoContainer>

      <ComoFuncionaContainer id="como-funciona">
        <div className="divLeft">
          <img src={mars1} alt="mars" />
          <video
            src={videoMars}
            type="video/mp4"
            autoPlay
            muted
            controls
          ></video>
        </div>
        <div className="divRight">
          <h2>
            Projeto gondôla <br></br>Full Digital Mars{" "}
          </h2>
          <div>
            <img src={mars1} alt="mars" />
            <video
              src={videoMars}
              type="video/mp4"
              autoPlay
              controls
              muted
              width={"100%"}
            ></video>
          </div>

          <p>
            Testeiras e faixas de gôndola Full HD, com troca remota{" "}
            {"(web e app)"} do conteúdo e dos preços, o que possibilita ativar
            mais de uma marca no PDV, fazer promoções instantâneas e coletar
            dados dos consumidores. Através de um ambiente instagramável e
            inovador.
          </p>
        </div>
      </ComoFuncionaContainer>

      <ProjetoCarnaval>
        <div className="divLeft">
          <h2>Projeto Carnaval Rack e Ilha Pepsico </h2>
          <div className="divRightMobile">
            <div className="imgBoxMobile">
              <img src={pepisco1} alt="pepisco" />
              <img src={pepisco2} alt="pepisco" />
            </div>
            <img src={pepisco3} alt="pepisco" />
          </div>

          <p>
            Troca remota {"(web e app)"} do conteúdo, possibilitando ativar
            várias marcas, fazer promoções instantâneas e coletar dados dos
            consumidores. Aumento de vendas em ate 60% e grande custo-benefício
            por conta da utilização de tablets e fácil positivação/instalação.
          </p>
        </div>

        <div className="divRight">
          <div className="imgBox">
            <img src={pepisco1} alt="pepisco" />
            <img src={pepisco2} alt="pepisco" />
          </div>
          <img src={pepisco3} alt="pepisco" />
        </div>
      </ProjetoCarnaval>

      <LiftLearnContainer>
        <div className="divLeft">
          <h2>
            Lift & Learn <br></br>na feira ABF{" "}
          </h2>
          <video
            src={videoLiftLearn}
            height="400px"
            width="300px"
            muted
            controls
            autoPlay
          />
        </div>
        <div className="divRight">
          <h2>
            Lift & Learn <br></br>na feira ABF{" "}
          </h2>
          <p>
            Além de telas de alta definição com troca remota de conteúdo e vídeo
            wall, trouxemos a tecnologia LIFT & LEARN, na qual ao levantar o
            produto da prateleira, aparece a comunicação referente ao mesmo
            produto no vídeo falando sobre os benefícios.
          </p>
        </div>
      </LiftLearnContainer>

      <ProjetoMars>
        <div className="divLeft">
          <h2>
            Projeto Rack<br></br>Digital Mars
          </h2>
          <p>
            Rack slim digital. Troca remota {"(web e app)"} do conteúdo,
            possibilitando ativar várias marcas, fazer promoções instantâneas e
            coletar dados dos consumidores. Aumento de vendas em ate 60% e
            grande custo-benefício por conta da utilização de tablets.
          </p>
        </div>
        <div className="divRight">
          <h2>
            Projeto Rack<br></br>Digital Mars
          </h2>
          <div className="imgBox">
            <img src={digitalMars1} alt="digitalMars" />
            <img src={digitalMars2} alt="digitalMars" />
          </div>
          <img src={digitalMars3} alt="digitalMars" />
        </div>
      </ProjetoMars>

      <ProjetoStopper>
        <div className="divLeft">
          <h2>
            Stopper e Faixa de <br></br>Gôndola Digital
          </h2>
          <div className="imgBox">
            <img src={stopper01} alt="stopper" />
            <img src={stopper02} alt="stopper" />
          </div>

          <img src={stopper03} alt="stopper" />
        </div>
        <div className="divRight">
          <h2>
            Stopper e Faixa de <br></br>Gôndola Digital
          </h2>
          <p>
            Stopper e faixa de gôndola digital. Troca remota {"(web e app)"} do
            conteúdo, possibilitando ativação conjunta de marcas{" "}
            {"(carne + cerveja)"}, interação com o shopper {"(tela touch)"} e
            precificador digital. Formatos diferenciados no mercado, gerando
            aumento nas vendas e da força da marca.
          </p>
        </div>
      </ProjetoStopper>

      <ProjetoAjinomoto>
        <div className="divLeft">
          <h2>
            Projeto Ajinomoto <br></br>Argentina
          </h2>
          <p>
            Ponta de gôndola de digital signage realizado na Argentina. Troca
            remota {"(web e app)"} do conteúdo, possibilitando ativar várias
            marcas, fazer promoções instantâneas e aumentar as vendas. Grande
            impacto visual no ponto de venda com o monitor 43".
          </p>
        </div>
        <div className="divRight">
          <h2>
            Projeto Ajinomoto <br></br>Argentina
          </h2>
          <div className="imgBox">
            <img src={ajinomoto01} alt="ajinomoto" />
            <img src={ajinomoto02} alt="ajinomoto" />
          </div>
          <img src={ajinomoto03} alt="ajinomoto" />
        </div>
      </ProjetoAjinomoto>

      <ProjetoTableTend>
        <div className="divLeft">
          <h2>Table Tent Digital</h2>
          <div className="imgBox">
            <img src={tableTend01} alt="tablet" />
            <img src={tableTend02} alt="tablet" />
          </div>
          <img src={tableTend03} alt="tablet" />
        </div>
        <div className="divRight">
          <h2>Table Tent Digital</h2>
          <p>
            Table tent ou cardápio digital. Troca remota {"(web e app)"} do
            conteúdo, interação com o consumidor via tela touch, suporte 360 e
            navegação totalmente digital. Tem a possibilidade de integração com
            o sistema de pedidos do restaurante/bar e realização do pagamento no
            próprio tablet.
          </p>
        </div>
      </ProjetoTableTend>

      <ProjetoGoose>
        <div className="divLeft">
          <h2>
            Goose Island<br></br>Check In
          </h2>
          <p>
            Desenvolvimento completo desde a integração do software com o
            sistema da casa, com o cartão ou pulseira para entrada, até a parte
            da produção da peça, vidro, montagem, design e telas.
          </p>
        </div>
        <div className="divRight">
          <h2>
            Goose Island<br></br>Check In
          </h2>
          <div className="imgBox">
            <img src={goose01} alt="goose" />
            <img src={goose02} alt="goose" />
          </div>
          <img src={goose03} alt="goose" />
        </div>
      </ProjetoGoose>

      <Solucoes>
        <div id className="content">
          <div id className="content-left">
            <h2>Já pensou no que poderíamos te ajudar?</h2>
          </div>
          <div className="content-right">
            <p>
              Nós possibilitamos que você vá mais longe através das nossas soluções
              e nosso atendimento ágil. Temos muito conhecimento nas àreas de
              tecnologia e Trade Marketing, o que nos proporciona um diferencial muito grande no mercado.
            </p>
            <Link to="/quem-somos">
              Conheça nossa empresa <img src={flecha} alt="flecha" />
            </Link>
          </div>
        </div>
      </Solucoes>

      <EntreEmContatoContainer id="clientes">
        <div className="content">
          <img src={elmaChipsForm} alt="elmaChips" />
          <FormEmail />
        </div>
      </EntreEmContatoContainer>
    </CasesContainer>
  );
};

export default Cases;
