import DigitalExperience from "../../components/DigitalExperience";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { DigitalExperiencePageContainer } from "./styles";
import ReactGA from 'react-ga';
import {useEffect} from 'react';
import WhatsappComponent from "../../components/WhatsappComponent";

const DigitalExperiencePage = () => {
    useEffect(()=>{
        ReactGA.pageview(window.location.pathname);
    },[])

    return (
        <DigitalExperiencePageContainer>
            <Header />
            <DigitalExperience />
            <Footer />
            <WhatsappComponent />
        </DigitalExperiencePageContainer>
    )
};

export default DigitalExperiencePage;